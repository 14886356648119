import { NewGameAdjustment$reflection, RankingTimePeriod$reflection, NewGameAdjustment as NewGameAdjustment_1, RankingTimePeriod } from "../../TryTheseGames.Website.Shared/Shared.fs.js";
import { join, interpolate, toText } from "../.fable/fable-library.3.1.12/String.js";
import { Record } from "../.fable/fable-library.3.1.12/Types.js";
import { record_type, option_type, int32_type } from "../.fable/fable-library.3.1.12/Reflection.js";
import { map as map_1, defaultArgWith } from "../.fable/fable-library.3.1.12/Option.js";
import { Option_toString, intTryParse } from "../Helpers.fs.js";
import { React_memo_62A0F746 } from "../.fable/Feliz.1.49.0/React.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.49.0/Interop.fs.js";
import { createElement } from "react";
import { Fa_IconOption, Fa_i } from "../.fable/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { map as map_3, append, singleton, delay, toList } from "../.fable/fable-library.3.1.12/Seq.js";
import { Helpers_combineClasses, Helpers_extractClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import { singleton as singleton_1, cons, ofArray, map as map_2 } from "../.fable/fable-library.3.1.12/List.js";
import { int32ToString, createObj } from "../.fable/fable-library.3.1.12/Util.js";
import { Input_NonEmptyNumber } from "../Components.fs.js";
import { Helpers_equalsButFunctions } from "../.fable/Fable.React.7.4.1/Fable.React.Helpers.fs.js";

export function RankingTimePeriod_toDisplayString(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return "One year";
        }
        case 2: {
            return "Two years";
        }
        case 3: {
            return "Four years";
        }
        default: {
            return "All";
        }
    }
}

export function RankingTimePeriod_fromString(_arg1) {
    switch (_arg1) {
        case "All": {
            return new RankingTimePeriod(0);
        }
        case "One year": {
            return new RankingTimePeriod(1);
        }
        case "Two years": {
            return new RankingTimePeriod(2);
        }
        case "Four years": {
            return new RankingTimePeriod(3);
        }
        default: {
            throw (new Error(toText(interpolate("Unknown %P()", ["RankingTimePeriod"]))));
        }
    }
}

export function NewGameAdjustment_toDisplayString(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return "Base";
        }
        case 2: {
            return "Mild";
        }
        case 3: {
            return "Moderate";
        }
        case 4: {
            return "Severe";
        }
        default: {
            return "None";
        }
    }
}

export function NewGameAdjustment_fromString(_arg1) {
    switch (_arg1) {
        case "None": {
            return new NewGameAdjustment_1(0);
        }
        case "Base": {
            return new NewGameAdjustment_1(1);
        }
        case "Mild": {
            return new NewGameAdjustment_1(2);
        }
        case "Moderate": {
            return new NewGameAdjustment_1(3);
        }
        case "Severe": {
            return new NewGameAdjustment_1(4);
        }
        default: {
            throw (new Error(toText(interpolate("Unknown %P()", ["NewGameAdjustment"]))));
        }
    }
}

export class RankingFilterModel extends Record {
    constructor(TimePeriod, NewGameAdjustment, PseudoRaters, PlayerCount, MinPlayTime, MaxPlayTime, MinPublishYear, MaxPublishYear, HasMechanic, HasCategory) {
        super();
        this.TimePeriod = TimePeriod;
        this.NewGameAdjustment = NewGameAdjustment;
        this.PseudoRaters = (PseudoRaters | 0);
        this.PlayerCount = PlayerCount;
        this.MinPlayTime = MinPlayTime;
        this.MaxPlayTime = MaxPlayTime;
        this.MinPublishYear = MinPublishYear;
        this.MaxPublishYear = MaxPublishYear;
        this.HasMechanic = HasMechanic;
        this.HasCategory = HasCategory;
    }
}

export function RankingFilterModel$reflection() {
    return record_type("Client.Ranking.RankingFilter.RankingFilterModel", [], RankingFilterModel, () => [["TimePeriod", RankingTimePeriod$reflection()], ["NewGameAdjustment", NewGameAdjustment$reflection()], ["PseudoRaters", int32_type], ["PlayerCount", option_type(int32_type)], ["MinPlayTime", option_type(int32_type)], ["MaxPlayTime", option_type(int32_type)], ["MinPublishYear", option_type(int32_type)], ["MaxPublishYear", option_type(int32_type)], ["HasMechanic", option_type(int32_type)], ["HasCategory", option_type(int32_type)]]);
}

export function withNewTimePeriod(filter, newTimePeriod) {
    return new RankingFilterModel(RankingTimePeriod_fromString(newTimePeriod), filter.NewGameAdjustment, filter.PseudoRaters, filter.PlayerCount, filter.MinPlayTime, filter.MaxPlayTime, filter.MinPublishYear, filter.MaxPublishYear, filter.HasMechanic, filter.HasCategory);
}

export function withNewNewGameAdjustment(filter, newAdjustment) {
    return new RankingFilterModel(filter.TimePeriod, NewGameAdjustment_fromString(newAdjustment), filter.PseudoRaters, filter.PlayerCount, filter.MinPlayTime, filter.MaxPlayTime, filter.MinPublishYear, filter.MaxPublishYear, filter.HasMechanic, filter.HasCategory);
}

export function withNewPseudoRaters(filter, newPenalty) {
    return new RankingFilterModel(filter.TimePeriod, filter.NewGameAdjustment, defaultArgWith(map_1((value) => (~(~value)), newPenalty), () => {
        const matchValue = filter.TimePeriod;
        switch (matchValue.tag) {
            case 1: {
                return 500;
            }
            case 2: {
                return 1000;
            }
            case 3: {
                return 1700;
            }
            default: {
                return 2100;
            }
        }
    }), filter.PlayerCount, filter.MinPlayTime, filter.MaxPlayTime, filter.MinPublishYear, filter.MaxPublishYear, filter.HasMechanic, filter.HasCategory);
}

export function withNewPlayerCount(filter, newCount) {
    return new RankingFilterModel(filter.TimePeriod, filter.NewGameAdjustment, filter.PseudoRaters, intTryParse(newCount), filter.MinPlayTime, filter.MaxPlayTime, filter.MinPublishYear, filter.MaxPublishYear, filter.HasMechanic, filter.HasCategory);
}

export function withNewMinPlayTime(filter, newMinPlayTime) {
    return new RankingFilterModel(filter.TimePeriod, filter.NewGameAdjustment, filter.PseudoRaters, filter.PlayerCount, intTryParse(newMinPlayTime), filter.MaxPlayTime, filter.MinPublishYear, filter.MaxPublishYear, filter.HasMechanic, filter.HasCategory);
}

export function withNewMaxPlayTime(filter, newMaxPlayTime) {
    return new RankingFilterModel(filter.TimePeriod, filter.NewGameAdjustment, filter.PseudoRaters, filter.PlayerCount, filter.MinPlayTime, intTryParse(newMaxPlayTime), filter.MinPublishYear, filter.MaxPublishYear, filter.HasMechanic, filter.HasCategory);
}

export function withNewMinPublishYear(filter, newMinPublishYear) {
    return new RankingFilterModel(filter.TimePeriod, filter.NewGameAdjustment, filter.PseudoRaters, filter.PlayerCount, filter.MinPlayTime, filter.MaxPlayTime, intTryParse(newMinPublishYear), filter.MaxPublishYear, filter.HasMechanic, filter.HasCategory);
}

export function withNewMaxPublishYear(filter, newMaxPublishYear) {
    return new RankingFilterModel(filter.TimePeriod, filter.NewGameAdjustment, filter.PseudoRaters, filter.PlayerCount, filter.MinPlayTime, filter.MaxPlayTime, filter.MinPublishYear, intTryParse(newMaxPublishYear), filter.HasMechanic, filter.HasCategory);
}

export function withNewHasCategory(filter, newHasCategory) {
    return new RankingFilterModel(filter.TimePeriod, filter.NewGameAdjustment, filter.PseudoRaters, filter.PlayerCount, filter.MinPlayTime, filter.MaxPlayTime, filter.MinPublishYear, filter.MaxPublishYear, filter.HasMechanic, intTryParse(newHasCategory));
}

export function withNewHasMechanic(filter, newHasMechanic) {
    return new RankingFilterModel(filter.TimePeriod, filter.NewGameAdjustment, filter.PseudoRaters, filter.PlayerCount, filter.MinPlayTime, filter.MaxPlayTime, filter.MinPublishYear, filter.MaxPublishYear, intTryParse(newHasMechanic), filter.HasCategory);
}

export function onQueryPropChanged(onChange, filter, map, prop) {
    return onChange(map(filter, prop));
}

export const rankingFilter = React_memo_62A0F746((props) => {
    let children_2, props_11, children, patternInput, children_6, props_21, children_4, patternInput_1, children_8, props_25, children_10, props_31, props_29, children_12, props_37, props_35, children_14, props_43, props_41, children_16, props_50, props_48, children_18, props_56, props_54, children_22, props_68, children_20, patternInput_2, matchValue, children_26, props_79, children_24, patternInput_3, matchValue_1;
    const props_89 = ofArray([["className", props.MobileFilterOpen ? "filter-box open" : "filter-box"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        className: "mobile-filter-button",
        onClick: (_arg1) => {
            props.OnMobileFilterToggled();
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "mobile-filter-button-text",
            children: Interop_reactApi.Children.toArray([Fa_i(toList(delay(() => (props.MobileFilterOpen ? singleton(new Fa_IconOption(11, "fas fa-chevron-double-down")) : singleton(new Fa_IconOption(11, "fas fa-chevron-double-up"))))), []), createElement("span", {
                children: ["Filter"],
            }), Fa_i(toList(delay(() => (props.MobileFilterOpen ? singleton(new Fa_IconOption(11, "fas fa-chevron-double-down")) : singleton(new Fa_IconOption(11, "fas fa-chevron-double-up"))))), [])]),
        })]),
    }), createElement("div", {
        className: "filter-container",
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "filter-options filter-subcontainer",
            children: Interop_reactApi.Children.toArray([(children_2 = singleton_1((props_11 = ofArray([["htmlFor", "timePeriod"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                children: ["Time period"],
            }), (children = singleton_1((patternInput = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["value", RankingTimePeriod_toDisplayString(props.Filter.TimePeriod)], ["onChange", (e) => onQueryPropChanged(props.OnFilterUpdated, props.Filter, (filter_1, newTimePeriod) => withNewTimePeriod(filter_1, newTimePeriod), Browser_Types_Event__Event_get_Value(e))], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map_2((v) => createElement("option", {
                value: v,
                children: v,
            }), map_2((_arg1_2) => RankingTimePeriod_toDisplayString(_arg1_2), ofArray([new RankingTimePeriod(0), new RankingTimePeriod(1), new RankingTimePeriod(2), new RankingTimePeriod(3)])))))))]])), createElement("div", {
                className: join(" ", cons("select", patternInput[0])),
                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
            }))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_11))))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            })), (children_6 = singleton_1((props_21 = ofArray([["htmlFor", "newGameAdjustment"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                children: ["New game adjustment"],
            }), (children_4 = singleton_1((patternInput_1 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["value", NewGameAdjustment_toDisplayString(props.Filter.NewGameAdjustment)], ["onChange", (e_1) => onQueryPropChanged(props.OnFilterUpdated, props.Filter, (filter_3, newAdjustment) => withNewNewGameAdjustment(filter_3, newAdjustment), Browser_Types_Event__Event_get_Value(e_1))], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map_2((v_1) => createElement("option", {
                value: v_1,
                children: v_1,
            }), map_2((_arg1_4) => NewGameAdjustment_toDisplayString(_arg1_4), ofArray([new NewGameAdjustment_1(0), new NewGameAdjustment_1(1), new NewGameAdjustment_1(2), new NewGameAdjustment_1(3), new NewGameAdjustment_1(4)])))))))]])), createElement("div", {
                className: join(" ", cons("select", patternInput_1[0])),
                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
            }))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            }))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_21))))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_6)),
            })), (children_8 = singleton_1((props_25 = ofArray([["htmlFor", "pseudoRaters"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                children: ["Pseudo raters"],
            }), createElement(Input_NonEmptyNumber, {
                Id: "pseudoRaters",
                OnChange: (prop_2) => onQueryPropChanged(props.OnFilterUpdated, props.Filter, (filter_5, newPenalty) => withNewPseudoRaters(filter_5, newPenalty), prop_2),
                Step: 100,
                Value: props.Filter.PseudoRaters,
            })])]]), createElement("label", createObj(Helpers_combineClasses("label", props_25))))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_8)),
            })), (children_10 = singleton_1((props_31 = ofArray([["htmlFor", "players"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                children: ["Number of players"],
            }), (props_29 = ofArray([["id", "players"], ["placeholder", "Any"], ["onChange", (ev) => onQueryPropChanged(props.OnFilterUpdated, props.Filter, (filter_7, newCount) => withNewPlayerCount(filter_7, newCount), ev.target.value)], ["value", Option_toString(props.Filter.PlayerCount)]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_29)))))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_31))))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_10)),
            })), createElement("div", {
                className: "range-input",
                children: Interop_reactApi.Children.toArray([(children_12 = singleton_1((props_37 = ofArray([["htmlFor", "minPlayTime"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                    children: ["Min play time (mins)"],
                }), (props_35 = ofArray([["id", "minPlayTime"], ["placeholder", "No Min"], ["onChange", (ev_1) => onQueryPropChanged(props.OnFilterUpdated, props.Filter, (filter_9, newMinPlayTime) => withNewMinPlayTime(filter_9, newMinPlayTime), ev_1.target.value)], ["value", Option_toString(props.Filter.MinPlayTime)]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_35)))))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_37))))), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_12)),
                })), (children_14 = singleton_1((props_43 = ofArray([["htmlFor", "maxPlayTime"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                    children: "Max play time (mins)",
                }), (props_41 = ofArray([["id", "maxPlayTime"], ["placeholder", "No Max"], ["onChange", (ev_2) => onQueryPropChanged(props.OnFilterUpdated, props.Filter, (filter_11, newMaxPlayTime) => withNewMaxPlayTime(filter_11, newMaxPlayTime), ev_2.target.value)], ["value", Option_toString(props.Filter.MaxPlayTime)]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_41)))))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_43))))), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_14)),
                }))]),
            }), createElement("div", {
                className: "range-input",
                children: Interop_reactApi.Children.toArray([(children_16 = singleton_1((props_50 = ofArray([["htmlFor", "publishedAfter"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                    children: ["Published after year"],
                }), (props_48 = ofArray([["id", "publishedAfter"], ["placeholder", "The beginning of time"], ["onChange", (ev_3) => onQueryPropChanged(props.OnFilterUpdated, props.Filter, (filter_13, newMinPublishYear) => withNewMinPublishYear(filter_13, newMinPublishYear), ev_3.target.value)], ["value", Option_toString(props.Filter.MinPublishYear)]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_48)))))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_50))))), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_16)),
                })), (children_18 = singleton_1((props_56 = ofArray([["htmlFor", "publishedBefore"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                    children: ["Published before year"],
                }), (props_54 = ofArray([["id", "publishedBefore"], ["placeholder", "Today"], ["onChange", (ev_4) => onQueryPropChanged(props.OnFilterUpdated, props.Filter, (filter_15, newMaxPublishYear) => withNewMaxPublishYear(filter_15, newMaxPublishYear), ev_4.target.value)], ["value", Option_toString(props.Filter.MaxPublishYear)]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_54)))))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_56))))), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_18)),
                }))]),
            }), (children_22 = singleton_1((props_68 = ofArray([["htmlFor", "hasMechanic"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                children: ["Has mechanic"],
            }), (children_20 = singleton_1((patternInput_2 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["value", (matchValue = props.Filter.HasMechanic, (matchValue == null) ? "" : int32ToString(matchValue))], ["onChange", (e_2) => onQueryPropChanged(props.OnFilterUpdated, props.Filter, (filter_17, newHasMechanic) => withNewHasMechanic(filter_17, newHasMechanic), Browser_Types_Event__Event_get_Value(e_2))], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("option", {
                value: "",
                children: "Any",
            })), delay(() => map_3((m_1) => createElement("option", {
                value: m_1.Id,
                children: m_1.Name,
            }), props.Mechanics)))))))]])), createElement("div", {
                className: join(" ", cons("select", patternInput_2[0])),
                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_2[1]))]),
            }))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_20)),
            }))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_68))))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_22)),
            })), (children_26 = singleton_1((props_79 = ofArray([["htmlFor", "hasCategory"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                children: ["Has category"],
            }), (children_24 = singleton_1((patternInput_3 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["value", (matchValue_1 = props.Filter.HasCategory, (matchValue_1 == null) ? "" : int32ToString(matchValue_1))], ["onChange", (e_3) => onQueryPropChanged(props.OnFilterUpdated, props.Filter, (filter_19, newHasCategory) => withNewHasCategory(filter_19, newHasCategory), Browser_Types_Event__Event_get_Value(e_3))], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("option", {
                value: "",
                children: "Any",
            })), delay(() => map_3((c) => createElement("option", {
                value: c.Id,
                children: c.Name,
            }), props.Categories)))))))]])), createElement("div", {
                className: join(" ", cons("select", patternInput_3[0])),
                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_3[1]))]),
            }))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_24)),
            }))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_79))))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_26)),
            }))]),
        }), createElement("div", {
            className: "filter-subcontainer",
            children: Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "clear-filter-button"], ["className", "is-fullwidth"], ["onClick", (_arg2) => {
                props.ClearFilter();
            }], ["children", "Clear filters"]])))), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "filter-button"], ["className", "is-info"], ["className", "is-fullwidth"], ["onClick", (_arg3) => {
                props.OnFilter();
            }], ["children", "Filter"]]))))]),
        })]),
    })])]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_89)));
}, void 0, (x, y) => Helpers_equalsButFunctions(x, y));

