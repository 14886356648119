import { Union } from "./.fable/fable-library.3.1.12/Types.js";
import { union_type, option_type, int32_type } from "./.fable/fable-library.3.1.12/Reflection.js";
import { interpolate, toText } from "./.fable/fable-library.3.1.12/String.js";
import { parsePath, oneOf, i32, s, map } from "./.fable/Fable.Elmish.Browser.3.0.4/parser.fs.js";
import { ofArray, collect } from "./.fable/fable-library.3.1.12/List.js";

export class Page extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Recommendations", "Rankings"];
    }
}

export function Page$reflection() {
    return union_type("Client.Pages.Page", [], Page, () => [[["Item", option_type(int32_type)]], []]);
}

export function toPath(_arg1) {
    if (_arg1.tag === 1) {
        return "/rankings";
    }
    else if (_arg1.fields[0] != null) {
        const id = _arg1.fields[0] | 0;
        return toText(interpolate("/game/%i%P()", [id]));
    }
    else {
        return "/";
    }
}

export const pageParser = (() => {
    let parseBefore;
    const parsers = ofArray([map(new Page(0, void 0), s("")), map((arg) => (new Page(0, arg)), (parseBefore = s("game"), (state_1) => collect((state) => i32(state), parseBefore(state_1)))), map(new Page(1), s("rankings"))]);
    return (state_3) => oneOf(parsers, state_3);
})();

export function urlParser(location) {
    return parsePath(pageParser, location);
}

