import { Union, Record } from "./.fable/fable-library.3.1.12/Types.js";
import { union_type, record_type, bool_type, tuple_type, class_type } from "./.fable/fable-library.3.1.12/Reflection.js";
import { comparePrimitives, max } from "./.fable/fable-library.3.1.12/Util.js";
import { now, op_Addition, op_Subtraction } from "./.fable/fable-library.3.1.12/Date.js";
import { Cmd_map, Cmd_batch, Cmd_OfFunc_result, Cmd_none, Cmd_OfPromise_either } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./.fable/Fable.Promise.2.2.2/Promise.fs.js";
import { promise } from "./.fable/Fable.Promise.2.2.2/PromiseImpl.fs.js";
import { empty, singleton, append, delay as delay_1, toList } from "./.fable/fable-library.3.1.12/Seq.js";

export class Model$1 extends Record {
    constructor(Delay, Input, Output, OutputDone) {
        super();
        this.Delay = Delay;
        this.Input = Input;
        this.Output = Output;
        this.OutputDone = OutputDone;
    }
}

export function Model$1$reflection(gen0) {
    return record_type("Elmish.Debounce.Model`1", [gen0], Model$1, () => [["Delay", class_type("System.TimeSpan")], ["Input", tuple_type(gen0, class_type("System.DateTime"))], ["Output", gen0], ["OutputDone", bool_type]]);
}

export function Model$1__get_TimeUntilOutput(this$) {
    return max((x, y) => comparePrimitives(x, y), op_Subtraction(op_Addition(this$.Input[1], this$.Delay), now()), 0);
}

export function init(delay, value) {
    return new Model$1(delay, [value, op_Subtraction(now(), delay)], value, true);
}

export class Msg$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Input", "TryOutput", "Output"];
    }
}

export function Msg$1$reflection(gen0) {
    return union_type("Elmish.Debounce.Msg`1", [gen0], Msg$1, () => [[["Item", gen0]], [], [["Item", gen0]]]);
}

function delayCmd(delay, msg) {
    return Cmd_OfPromise_either(() => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => ((new Promise(resolve => setTimeout(resolve, (~(~delay))))).then((() => (Promise.resolve(undefined))))))), void 0, () => msg, (_arg1_1) => msg);
}

function updateInternal(msg, model) {
    switch (msg.tag) {
        case 1: {
            return [model, model.OutputDone ? Cmd_none() : ((Model$1__get_TimeUntilOutput(model) <= 0) ? Cmd_OfFunc_result(new Msg$1(2, model.Input[0])) : delayCmd(Model$1__get_TimeUntilOutput(model), new Msg$1(1)))];
        }
        case 2: {
            return [model.OutputDone ? model : (new Model$1(model.Delay, model.Input, msg.fields[0], true)), Cmd_none()];
        }
        default: {
            return [new Model$1(model.Delay, [msg.fields[0], now()], model.Output, false), delayCmd(Model$1__get_TimeUntilOutput(model), new Msg$1(1))];
        }
    }
}

export function inputCmd(value, wrapMsg) {
    return Cmd_OfFunc_result(wrapMsg(new Msg$1(0, value)));
}

export function updateWithCmd(msg, wrapMsg, model, wrapModel, cmdOnOutput) {
    const patternInput = updateInternal(msg, model);
    const cmd$0027 = Cmd_batch(toList(delay_1(() => append(singleton(Cmd_map(wrapMsg, patternInput[1])), delay_1(() => {
        if (msg.tag === 2) {
            return singleton(cmdOnOutput(msg.fields[0]));
        }
        else {
            return empty();
        }
    })))));
    return [wrapModel(patternInput[0]), cmd$0027];
}

export function update(msg, wrapMsg, model, wrapModel) {
    return updateWithCmd(msg, wrapMsg, model, wrapModel, (_arg1) => Cmd_none());
}

