import { Union, Record } from "../.fable/fable-library.3.1.12/Types.js";
import { union_type, class_type, record_type, bool_type, list_type, string_type } from "../.fable/fable-library.3.1.12/Reflection.js";
import { BoardGame$reflection } from "../../TryTheseGames.Website.Shared/Shared.fs.js";
import { CancellableDeferred$1, AsyncOperation$1, AsyncOperation$1$reflection, CancellableDeferred$1$reflection } from "../Helpers.fs.js";
import { inputCmd, updateWithCmd, init as init_1, Msg$1$reflection, Model$1$reflection } from "../Debounce.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.1.12/Choice.js";
import { Auto_generateBoxedDecoder_79988AEF } from "../.fable/Thoth.Json.6.0.0/Decode.fs.js";
import { fetchAs } from "../FetchHelpers.fs.js";
import { interpolate, toText } from "../.fable/fable-library.3.1.12/String.js";
import { createAtom, createObj, uncurry } from "../.fable/fable-library.3.1.12/Util.js";
import { Cmd_OfFunc_result, Cmd_none, Cmd_OfPromise_either } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { Navigation_modifyUrl } from "../.fable/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { Page, toPath } from "../Pages.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.49.0/Interop.fs.js";
import { singleton as singleton_1, toArray, ofArray } from "../.fable/fable-library.3.1.12/List.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { empty, singleton, append, delay, toList } from "../.fable/fable-library.3.1.12/Seq.js";
import react$002Dautosuggest from "react-autosuggest";
import { Fa_IconOption, Fa_span } from "../.fable/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { DOMAttr } from "../.fable/Fable.React.7.4.1/Fable.React.Props.fs.js";

export class Model extends Record {
    constructor(SearchText, GameOptions, ShowOptions, DebouncedSearchText, AutoFocus) {
        super();
        this.SearchText = SearchText;
        this.GameOptions = GameOptions;
        this.ShowOptions = ShowOptions;
        this.DebouncedSearchText = DebouncedSearchText;
        this.AutoFocus = AutoFocus;
    }
}

export function Model$reflection() {
    return record_type("Client.Recommendations.GameSearcher.Model", [], Model, () => [["SearchText", string_type], ["GameOptions", CancellableDeferred$1$reflection(list_type(BoardGame$reflection()))], ["ShowOptions", bool_type], ["DebouncedSearchText", Model$1$reflection(string_type)], ["AutoFocus", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetSearchText", "SetShowOptions", "SearchGames", "DebouncedSearchTextChanged", "GameSelected"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Recommendations.GameSearcher.Msg", [], Msg, () => [[["Text", string_type], ["ChangeResults", bool_type]], [["Item", bool_type]], [["Item", AsyncOperation$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(BoardGame$reflection()), class_type("System.Exception")], FSharpResult$2, () => [[["ResultValue", list_type(BoardGame$reflection())]], [["ErrorValue", class_type("System.Exception")]]]))]], [["Item", Msg$1$reflection(string_type)]], [["Item", BoardGame$reflection()]]]);
}

export const boardGameListDecoder = Auto_generateBoxedDecoder_79988AEF(list_type(BoardGame$reflection()), void 0, void 0);

export function searchGames(query) {
    const patternInput = fetchAs(toText(interpolate("/api/games?query=%s%P()", [query])), uncurry(2, boardGameListDecoder));
    return [Cmd_OfPromise_either(() => patternInput[0], void 0, (arg_1) => (new Msg(2, new AsyncOperation$1(1, new FSharpResult$2(0, arg_1)))), (arg_3) => (new Msg(2, new AsyncOperation$1(1, new FSharpResult$2(1, arg_3))))), patternInput[1]];
}

export function init(autoFocus) {
    return new Model("", new CancellableDeferred$1(0), false, init_1(200, ""), autoFocus);
}

export function update(msg, currentModel) {
    if (msg.tag === 1) {
        return [new Model(currentModel.SearchText, currentModel.GameOptions, msg.fields[0], currentModel.DebouncedSearchText, currentModel.AutoFocus), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return updateWithCmd(msg.fields[0], (arg0_1) => (new Msg(3, arg0_1)), currentModel.DebouncedSearchText, (x) => (new Model(currentModel.SearchText, currentModel.GameOptions, currentModel.ShowOptions, x, currentModel.AutoFocus)), (_arg1) => Cmd_OfFunc_result(new Msg(2, new AsyncOperation$1(0))));
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                void toText(interpolate("%A%P()", [msg.fields[0].fields[0].fields[0]]));
                return [currentModel, Cmd_none()];
            }
            else {
                return [new Model(currentModel.SearchText, new CancellableDeferred$1(2, msg.fields[0].fields[0].fields[0]), currentModel.ShowOptions, currentModel.DebouncedSearchText, currentModel.AutoFocus), Cmd_none()];
            }
        }
        else {
            const matchValue = currentModel.GameOptions;
            if (matchValue.tag === 1) {
                matchValue.fields[0]();
            }
            if (currentModel.SearchText === "") {
                return [new Model(currentModel.SearchText, new CancellableDeferred$1(0), currentModel.ShowOptions, currentModel.DebouncedSearchText, currentModel.AutoFocus), Cmd_none()];
            }
            else {
                const patternInput = searchGames(currentModel.SearchText);
                return [new Model(currentModel.SearchText, new CancellableDeferred$1(1, patternInput[1]), currentModel.ShowOptions, currentModel.DebouncedSearchText, currentModel.AutoFocus), patternInput[0]];
            }
        }
    }
    else if (msg.tag === 4) {
        return [new Model(msg.fields[0].Name, currentModel.GameOptions, currentModel.ShowOptions, currentModel.DebouncedSearchText, currentModel.AutoFocus), Navigation_modifyUrl(toPath(new Page(0, msg.fields[0].Id)))];
    }
    else {
        return [new Model(msg.fields[0], msg.fields[1] ? (new CancellableDeferred$1(0)) : currentModel.GameOptions, currentModel.ShowOptions, currentModel.DebouncedSearchText, currentModel.AutoFocus), msg.fields[1] ? inputCmd(msg.fields[0], (arg0) => (new Msg(3, arg0))) : Cmd_none()];
    }
}

export function renderSuggestion(game, details) {
    let props_1, matchValue_1, url;
    const itemClassNames = details.isHighlighted ? "item highlighted" : "item";
    let alternativeNameString;
    const matchValue = game.AlternativeName;
    alternativeNameString = ((matchValue == null) ? "" : toText(interpolate("(%s%P())", [matchValue])));
    return createElement("div", {
        className: itemClassNames,
        children: Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", "is-32x32"], ["children", Interop_reactApi.Children.toArray([(matchValue_1 = game.Thumbnail, (matchValue_1 == null) ? null : (url = matchValue_1, createElement("div", {
            className: "game-image",
            style: {
                backgroundImage: ("url(\u0027" + url) + "\u0027)",
                backgroundSize: "cover",
            },
        })))])]]), createElement("figure", createObj(Helpers_combineClasses("image", props_1)))), createElement("div", {
            className: "name-suggestion",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "primary-name",
                children: toText(interpolate("%s%P() (%i%P()) ", [game.Name, game.YearPublished])),
            }), createElement("div", {
                className: "alternative-name",
                children: alternativeNameString,
            })]),
        })]),
    });
}

export const inputRef = createAtom(null);

export function render(model, dispatch) {
    let props_2;
    const children = singleton_1((props_2 = toList(delay(() => append((model.GameOptions.tag === 1) ? singleton(["className", "is-loading"]) : (void 0, empty()), delay(() => singleton(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let matchValue_1;
        return append(singleton(Interop_reactApi.createElement(react$002Dautosuggest, {
            suggestions: (matchValue_1 = model.GameOptions, (matchValue_1.tag === 2) ? (model.ShowOptions ? toArray(matchValue_1.fields[0]) : []) : []),
            onSuggestionsFetchRequested: (_arg1) => {
                dispatch(new Msg(1, true));
            },
            onSuggestionsClearRequested: () => {
                dispatch(new Msg(1, false));
            },
            getSuggestionValue: (suggestion) => suggestion.Name,
            renderSuggestion: (delegateArg0, delegateArg1) => renderSuggestion(delegateArg0, delegateArg1),
            onSuggestionSelected: (delegateArg0_1, delegateArg1_1) => {
                dispatch(new Msg(4, delegateArg1_1.suggestion));
            },
            inputProps: {
                className: "input",
                placeholder: "Search for a board game",
                ref: (ref) => {
                    inputRef(ref, true);
                },
                value: model.SearchText,
                onChange: (delegateArg0_2, delegateArg1_2) => {
                    let matchValue_2;
                    const o = delegateArg1_2;
                    dispatch(new Msg(0, o.newValue, (matchValue_2 = o.method, (matchValue_2 === "up") ? false : ((matchValue_2 === "escape") ? false : ((matchValue_2 === "enter") ? false : ((matchValue_2 === "click") ? false : ((matchValue_2 === "type") ? true : false)))))));
                },
            },
        })), delay(() => {
            if (model.SearchText.length > 0) {
                return singleton(Fa_span(ofArray([new Fa_IconOption(11, "fas fa-times-circle"), new Fa_IconOption(15, "clear-search"), new Fa_IconOption(14, singleton_1(new DOMAttr(40, (_arg4) => {
                    inputRef().focus();
                    dispatch(new Msg(0, "", false));
                })))]), []));
            }
            else {
                return empty();
            }
        }));
    }))))]))))), createElement("div", createObj(Helpers_combineClasses("control", props_2)))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

