import { Union, Record } from "../.fable/fable-library.3.1.12/Types.js";
import { union_type, class_type, list_type, bool_type, record_type, array_type, option_type, string_type, int32_type } from "../.fable/fable-library.3.1.12/Reflection.js";
import { TimeTrendData, BoardGame$reflection, Page$1$reflection, Category$reflection, Mechanic$reflection, BoardGameRecommendation$reflection, TimeTrendData$reflection } from "../../TryTheseGames.Website.Shared/Shared.fs.js";
import { toQueryString, Deferred$1, CancellablePagedDeferred$1, CancellableDeferred$1, AsyncOperation$1, AsyncOperation$1$reflection, Deferred$1$reflection, CancellablePagedDeferred$1$reflection, CancellableDeferred$1$reflection } from "../Helpers.fs.js";
import { render as render_1, update as update_2, Msg as Msg_2, init as init_1, Msg$reflection as Msg$reflection_1, Model$reflection as Model$reflection_1 } from "./GameSearcher.fs.js";
import { render as render_2, Msg as Msg_1, Filter__ToRecommendationsFilter, update as update_1, init as init_2, Msg$reflection as Msg$reflection_2, Model$reflection as Model$reflection_2 } from "./RecommendationFilter.fs.js";
import { Auto_generateBoxedDecoder_79988AEF } from "../.fable/Thoth.Json.6.0.0/Decode.fs.js";
import { fetchAs } from "../FetchHelpers.fs.js";
import { createObj, uncurry } from "../.fable/fable-library.3.1.12/Util.js";
import { Cmd_none, Cmd_map, Cmd_OfFunc_result, Cmd_batch, Cmd_OfPromise_either } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { interpolate, toText } from "../.fable/fable-library.3.1.12/String.js";
import { empty, singleton, append, delay, toList } from "../.fable/fable-library.3.1.12/Seq.js";
import { isEmpty, singleton as singleton_1, map, tryFind, length, append as append_1, ofArray } from "../.fable/fable-library.3.1.12/List.js";
import { some } from "../.fable/fable-library.3.1.12/Option.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.49.0/Interop.fs.js";
import { Fa_i, Fa_Stack_Option, Fa_stack, Fa_ISize, Fa_IconOption, Fa_span } from "../.fable/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { rangeDouble } from "../.fable/fable-library.3.1.12/Range.js";
import { timeTrend as timeTrend_1 } from "../Components.fs.js";
import { viewLinkButton, viewLink } from "../PushStateLink.fs.js";
import { Page } from "../Pages.fs.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { recommendationsGlossaryModal, moreInfoModal } from "../Modals.fs.js";

export class SelectedGame extends Record {
    constructor(Id, Name, Thumbnail, YearPublished, MinPlayers, MaxPlayers, MinPlayingTime, MaxPlayingTime, MechanicIds, CategoryIds, TimeTrend) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Thumbnail = Thumbnail;
        this.YearPublished = (YearPublished | 0);
        this.MinPlayers = (MinPlayers | 0);
        this.MaxPlayers = (MaxPlayers | 0);
        this.MinPlayingTime = (MinPlayingTime | 0);
        this.MaxPlayingTime = (MaxPlayingTime | 0);
        this.MechanicIds = MechanicIds;
        this.CategoryIds = CategoryIds;
        this.TimeTrend = TimeTrend;
    }
}

export function SelectedGame$reflection() {
    return record_type("Client.Recommendations.RecommendationsPage.SelectedGame", [], SelectedGame, () => [["Id", int32_type], ["Name", string_type], ["Thumbnail", option_type(string_type)], ["YearPublished", int32_type], ["MinPlayers", int32_type], ["MaxPlayers", int32_type], ["MinPlayingTime", int32_type], ["MaxPlayingTime", int32_type], ["MechanicIds", array_type(int32_type)], ["CategoryIds", array_type(int32_type)], ["TimeTrend", CancellableDeferred$1$reflection(TimeTrendData$reflection())]]);
}

export class Model extends Record {
    constructor(MoreInfoModalActive, GlossaryModalActive, GameSearcher, RecommendationFilter, SelectedGameId, SelectedGame, Recommendations, Mechanics, Categories) {
        super();
        this.MoreInfoModalActive = MoreInfoModalActive;
        this.GlossaryModalActive = GlossaryModalActive;
        this.GameSearcher = GameSearcher;
        this.RecommendationFilter = RecommendationFilter;
        this.SelectedGameId = SelectedGameId;
        this.SelectedGame = SelectedGame;
        this.Recommendations = Recommendations;
        this.Mechanics = Mechanics;
        this.Categories = Categories;
    }
}

export function Model$reflection() {
    return record_type("Client.Recommendations.RecommendationsPage.Model", [], Model, () => [["MoreInfoModalActive", bool_type], ["GlossaryModalActive", bool_type], ["GameSearcher", Model$reflection_1()], ["RecommendationFilter", Model$reflection_2()], ["SelectedGameId", option_type(int32_type)], ["SelectedGame", CancellableDeferred$1$reflection(SelectedGame$reflection())], ["Recommendations", CancellablePagedDeferred$1$reflection(list_type(BoardGameRecommendation$reflection()))], ["Mechanics", Deferred$1$reflection(list_type(Mechanic$reflection()))], ["Categories", Deferred$1$reflection(list_type(Category$reflection()))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GameSearcherMsg", "RecommendationFilterMsg", "LoadRecommendations", "LoadMechanics", "LoadCategories", "LoadSelectedGame", "LikeGame", "LoadTimeTrend", "ToggleMoreInfoModal", "ToggleGlossaryModal", "LogError"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Recommendations.RecommendationsPage.Msg", [], Msg, () => [[["Item", Msg$reflection_1()]], [["Item", Msg$reflection_2()]], [["Item", AsyncOperation$1$reflection(Page$1$reflection(BoardGameRecommendation$reflection()))]], [["Item", AsyncOperation$1$reflection(list_type(Mechanic$reflection()))]], [["Item", AsyncOperation$1$reflection(list_type(Category$reflection()))]], [["Item", AsyncOperation$1$reflection(BoardGame$reflection())]], [["Item", int32_type]], [["Item", AsyncOperation$1$reflection(TimeTrendData$reflection())]], [], [], [["Item", class_type("System.Exception")]]]);
}

export const mechanicsDecoder = Auto_generateBoxedDecoder_79988AEF(list_type(Mechanic$reflection()), void 0, void 0);

export function getMechanicsCmd() {
    const patternInput = fetchAs("/api/mechanics", uncurry(2, mechanicsDecoder));
    return Cmd_OfPromise_either(() => patternInput[0], void 0, (arg) => (new Msg(3, new AsyncOperation$1(1, arg))), (arg0_2) => (new Msg(10, arg0_2)));
}

export const categoriesDecoder = Auto_generateBoxedDecoder_79988AEF(list_type(Category$reflection()), void 0, void 0);

export function getCategoriesCmd() {
    const patternInput = fetchAs("/api/categories", uncurry(2, categoriesDecoder));
    return Cmd_OfPromise_either(() => patternInput[0], void 0, (arg) => (new Msg(4, new AsyncOperation$1(1, arg))), (arg0_2) => (new Msg(10, arg0_2)));
}

export const gameDecoder = Auto_generateBoxedDecoder_79988AEF(BoardGame$reflection(), void 0, void 0);

export function getGameCmd(id) {
    const patternInput = fetchAs(toText(interpolate("/api/games/%i%P()", [id])), uncurry(2, gameDecoder));
    return [Cmd_OfPromise_either(() => patternInput[0], void 0, (arg) => (new Msg(5, new AsyncOperation$1(1, arg))), (arg0_2) => (new Msg(10, arg0_2))), patternInput[1]];
}

export const timeTrendDecoder = Auto_generateBoxedDecoder_79988AEF(TimeTrendData$reflection(), void 0, void 0);

export function getTimeTrendDataCmd(id) {
    const patternInput = fetchAs(toText(interpolate("/api/games/%i%P()/time-trend", [id])), uncurry(2, timeTrendDecoder));
    return [Cmd_OfPromise_either(() => patternInput[0], void 0, (arg) => (new Msg(7, new AsyncOperation$1(1, arg))), (arg0_2) => (new Msg(10, arg0_2))), patternInput[1]];
}

export function init(gameId) {
    return [new Model(false, false, init_1(false), init_2(), gameId, new CancellableDeferred$1(0), new CancellablePagedDeferred$1(0), new Deferred$1(0), new Deferred$1(0)), Cmd_batch(toList(delay(() => append(singleton(Cmd_OfFunc_result(new Msg(3, new AsyncOperation$1(0)))), delay(() => append(singleton(Cmd_OfFunc_result(new Msg(4, new AsyncOperation$1(0)))), delay(() => {
        if (gameId == null) {
            return empty();
        }
        else {
            return append(singleton(Cmd_OfFunc_result(new Msg(2, new AsyncOperation$1(0)))), delay(() => singleton(Cmd_OfFunc_result(new Msg(5, new AsyncOperation$1(0))))));
        }
    })))))))];
}

export const recommendationDecoder = Auto_generateBoxedDecoder_79988AEF(Page$1$reflection(BoardGameRecommendation$reflection()), void 0, void 0);

export function getRecommendationsCmd(filter) {
    const patternInput = fetchAs(toText(interpolate("/api/games/%i%P()/recommendations%s%P()", [filter.GameId, toQueryString(filter, ["gameId"])])), uncurry(2, recommendationDecoder));
    return [Cmd_OfPromise_either(() => patternInput[0], void 0, (arg) => (new Msg(2, new AsyncOperation$1(1, arg))), (arg0_2) => (new Msg(10, arg0_2))), patternInput[1]];
}

export function mapRecommendationToSelectedGame(g) {
    return new SelectedGame(g.Id, g.Name, g.Thumbnail, g.YearPublished, g.MinPlayers, g.MaxPlayers, g.MinPlayingTime, g.MaxPlayingTime, g.MechanicIds, g.CategoryIds, new CancellableDeferred$1(0));
}

export function mapToSelectedGame(g) {
    return new SelectedGame(g.Id, g.Name, g.Thumbnail, g.YearPublished, g.MinPlayers, g.MaxPlayers, g.MinPlayingTime, g.MaxPlayingTime, g.MechanicIds, g.CategoryIds, new CancellableDeferred$1(2, new TimeTrendData(g.RatingTrendCoefficients, g.RatingTrendStartDate, g.RatingTrendEndDate)));
}

export function update(msg, state) {
    let matchValue_3;
    if (msg.tag === 1) {
        const patternInput_2 = update_1(msg.fields[0], state.RecommendationFilter);
        const newState_2 = new Model(state.MoreInfoModalActive, state.GlossaryModalActive, state.GameSearcher, patternInput_2[0], state.SelectedGameId, state.SelectedGame, state.Recommendations, state.Mechanics, state.Categories);
        const cmd_4 = Cmd_map((arg0_2) => (new Msg(1, arg0_2)), patternInput_2[1]);
        if (msg.fields[0].tag === 14) {
            return [new Model(newState_2.MoreInfoModalActive, newState_2.GlossaryModalActive, newState_2.GameSearcher, newState_2.RecommendationFilter, newState_2.SelectedGameId, newState_2.SelectedGame, new CancellablePagedDeferred$1(0), newState_2.Mechanics, newState_2.Categories), Cmd_batch(ofArray([cmd_4, Cmd_OfFunc_result(new Msg(2, new AsyncOperation$1(0)))]))];
        }
        else {
            return [newState_2, cmd_4];
        }
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            let items;
            const matchValue_4 = state.Recommendations;
            let pattern_matching_result, rs_2;
            switch (matchValue_4.tag) {
                case 1: {
                    pattern_matching_result = 0;
                    break;
                }
                case 2: {
                    pattern_matching_result = 1;
                    rs_2 = matchValue_4.fields[0];
                    break;
                }
                case 3: {
                    pattern_matching_result = 1;
                    rs_2 = matchValue_4.fields[0];
                    break;
                }
                default: pattern_matching_result = 0}
            switch (pattern_matching_result) {
                case 0: {
                    items = msg.fields[0].fields[0].Items;
                    break;
                }
                case 1: {
                    items = append_1(rs_2, msg.fields[0].fields[0].Items);
                    break;
                }
            }
            return [new Model(state.MoreInfoModalActive, state.GlossaryModalActive, state.GameSearcher, state.RecommendationFilter, state.SelectedGameId, state.SelectedGame, new CancellablePagedDeferred$1(3, items, length(items) < msg.fields[0].fields[0].TotalCount), state.Mechanics, state.Categories), Cmd_none()];
        }
        else {
            const matchValue = state.Recommendations;
            let pattern_matching_result_1, cancel;
            switch (matchValue.tag) {
                case 1: {
                    pattern_matching_result_1 = 0;
                    cancel = matchValue.fields[0];
                    break;
                }
                case 2: {
                    pattern_matching_result_1 = 0;
                    cancel = matchValue.fields[1];
                    break;
                }
                default: pattern_matching_result_1 = 1}
            switch (pattern_matching_result_1) {
                case 0: {
                    cancel();
                    break;
                }
            }
            let skip;
            const matchValue_1 = state.Recommendations;
            skip = ((matchValue_1.tag === 3) ? length(matchValue_1.fields[0]) : 0);
            const matchValue_2 = state.SelectedGameId;
            if (matchValue_2 == null) {
                return [state, Cmd_none()];
            }
            else {
                const patternInput_3 = getRecommendationsCmd(Filter__ToRecommendationsFilter(state.RecommendationFilter.Filter, matchValue_2, skip));
                const cancel_1 = patternInput_3[1];
                return [new Model(state.MoreInfoModalActive, state.GlossaryModalActive, state.GameSearcher, state.RecommendationFilter, state.SelectedGameId, state.SelectedGame, (matchValue_3 = state.Recommendations, (matchValue_3.tag === 1) ? (new CancellablePagedDeferred$1(1, cancel_1)) : ((matchValue_3.tag === 2) ? (new CancellablePagedDeferred$1(1, cancel_1)) : ((matchValue_3.tag === 3) ? (new CancellablePagedDeferred$1(2, matchValue_3.fields[0], cancel_1)) : (new CancellablePagedDeferred$1(1, cancel_1))))), state.Mechanics, state.Categories), patternInput_3[0]];
            }
        }
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            return [new Model(state.MoreInfoModalActive, state.GlossaryModalActive, state.GameSearcher, state.RecommendationFilter, state.SelectedGameId, state.SelectedGame, state.Recommendations, new Deferred$1(2, msg.fields[0].fields[0]), state.Categories), Cmd_OfFunc_result(new Msg(1, new Msg_1(10, msg.fields[0].fields[0])))];
        }
        else {
            return [new Model(state.MoreInfoModalActive, state.GlossaryModalActive, state.GameSearcher, state.RecommendationFilter, state.SelectedGameId, state.SelectedGame, state.Recommendations, new Deferred$1(1), state.Categories), getMechanicsCmd()];
        }
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 1) {
            return [new Model(state.MoreInfoModalActive, state.GlossaryModalActive, state.GameSearcher, state.RecommendationFilter, state.SelectedGameId, state.SelectedGame, state.Recommendations, state.Mechanics, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_OfFunc_result(new Msg(1, new Msg_1(11, msg.fields[0].fields[0])))];
        }
        else {
            return [new Model(state.MoreInfoModalActive, state.GlossaryModalActive, state.GameSearcher, state.RecommendationFilter, state.SelectedGameId, state.SelectedGame, state.Recommendations, state.Mechanics, new Deferred$1(1)), getCategoriesCmd()];
        }
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            return [new Model(state.MoreInfoModalActive, state.GlossaryModalActive, state.GameSearcher, state.RecommendationFilter, state.SelectedGameId, new CancellableDeferred$1(2, mapToSelectedGame(msg.fields[0].fields[0])), state.Recommendations, state.Mechanics, state.Categories), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(0, new Msg_2(0, msg.fields[0].fields[0].Name, true))), Cmd_OfFunc_result(new Msg(1, new Msg_1(12, msg.fields[0].fields[0].MechanicIds, msg.fields[0].fields[0].CategoryIds)))]))];
        }
        else {
            const matchValue_5 = state.SelectedGame;
            if (matchValue_5.tag === 1) {
                matchValue_5.fields[0]();
            }
            const matchValue_6 = state.SelectedGameId;
            if (matchValue_6 == null) {
                return [state, Cmd_none()];
            }
            else {
                const patternInput_4 = getGameCmd(matchValue_6);
                return [new Model(state.MoreInfoModalActive, state.GlossaryModalActive, state.GameSearcher, state.RecommendationFilter, state.SelectedGameId, new CancellableDeferred$1(1, patternInput_4[1]), state.Recommendations, state.Mechanics, state.Categories), patternInput_4[0]];
            }
        }
    }
    else if (msg.tag === 6) {
        let promotedGame;
        const matchValue_7 = state.Recommendations;
        let pattern_matching_result_2, rs_3;
        switch (matchValue_7.tag) {
            case 2: {
                pattern_matching_result_2 = 0;
                rs_3 = matchValue_7.fields[0];
                break;
            }
            case 3: {
                pattern_matching_result_2 = 0;
                rs_3 = matchValue_7.fields[0];
                break;
            }
            default: pattern_matching_result_2 = 1}
        switch (pattern_matching_result_2) {
            case 0: {
                promotedGame = tryFind((r) => (r.Id === msg.fields[0]), rs_3);
                break;
            }
            case 1: {
                promotedGame = (void 0);
                break;
            }
        }
        if (promotedGame == null) {
            return [new Model(state.MoreInfoModalActive, state.GlossaryModalActive, state.GameSearcher, state.RecommendationFilter, msg.fields[0], new CancellableDeferred$1(0), new CancellablePagedDeferred$1(0), state.Mechanics, state.Categories), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(5, new AsyncOperation$1(0))), Cmd_OfFunc_result(new Msg(2, new AsyncOperation$1(0)))]))];
        }
        else {
            const game_1 = promotedGame;
            return [new Model(state.MoreInfoModalActive, state.GlossaryModalActive, state.GameSearcher, state.RecommendationFilter, msg.fields[0], new CancellableDeferred$1(2, mapRecommendationToSelectedGame(game_1)), new CancellablePagedDeferred$1(0), state.Mechanics, state.Categories), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(1, new Msg_1(12, game_1.MechanicIds, game_1.CategoryIds))), Cmd_OfFunc_result(new Msg(0, new Msg_2(0, game_1.Name, true))), Cmd_OfFunc_result(new Msg(7, new AsyncOperation$1(0))), Cmd_OfFunc_result(new Msg(2, new AsyncOperation$1(0)))]))];
        }
    }
    else if (msg.tag === 7) {
        if (msg.fields[0].tag === 1) {
            const matchValue_11 = state.SelectedGame;
            if (matchValue_11.tag === 2) {
                const game_3 = matchValue_11.fields[0];
                return [new Model(state.MoreInfoModalActive, state.GlossaryModalActive, state.GameSearcher, state.RecommendationFilter, state.SelectedGameId, new CancellableDeferred$1(2, new SelectedGame(game_3.Id, game_3.Name, game_3.Thumbnail, game_3.YearPublished, game_3.MinPlayers, game_3.MaxPlayers, game_3.MinPlayingTime, game_3.MaxPlayingTime, game_3.MechanicIds, game_3.CategoryIds, new CancellableDeferred$1(2, msg.fields[0].fields[0]))), state.Recommendations, state.Mechanics, state.Categories), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        else {
            const matchValue_8 = state.SelectedGame;
            if (matchValue_8.tag === 2) {
                const game_2 = matchValue_8.fields[0];
                const matchValue_9 = game_2.TimeTrend;
                if (matchValue_9.tag === 1) {
                    matchValue_9.fields[0]();
                }
                const matchValue_10 = state.SelectedGameId;
                if (matchValue_10 == null) {
                    return [state, Cmd_none()];
                }
                else {
                    const patternInput_5 = getTimeTrendDataCmd(matchValue_10);
                    return [new Model(state.MoreInfoModalActive, state.GlossaryModalActive, state.GameSearcher, state.RecommendationFilter, state.SelectedGameId, new CancellableDeferred$1(2, new SelectedGame(game_2.Id, game_2.Name, game_2.Thumbnail, game_2.YearPublished, game_2.MinPlayers, game_2.MaxPlayers, game_2.MinPlayingTime, game_2.MaxPlayingTime, game_2.MechanicIds, game_2.CategoryIds, new CancellableDeferred$1(1, patternInput_5[1]))), state.Recommendations, state.Mechanics, state.Categories), patternInput_5[0]];
                }
            }
            else {
                return [state, Cmd_none()];
            }
        }
    }
    else if (msg.tag === 8) {
        return [new Model(!state.MoreInfoModalActive, state.GlossaryModalActive, state.GameSearcher, state.RecommendationFilter, state.SelectedGameId, state.SelectedGame, state.Recommendations, state.Mechanics, state.Categories), Cmd_none()];
    }
    else if (msg.tag === 9) {
        return [new Model(state.MoreInfoModalActive, !state.GlossaryModalActive, state.GameSearcher, state.RecommendationFilter, state.SelectedGameId, state.SelectedGame, state.Recommendations, state.Mechanics, state.Categories), Cmd_none()];
    }
    else if (msg.tag === 10) {
        console.error(some(msg.fields[0]));
        return [state, Cmd_none()];
    }
    else if (msg.fields[0].tag === 4) {
        const patternInput = update_2(new Msg_2(4, msg.fields[0].fields[0]), state.GameSearcher);
        return [new Model(state.MoreInfoModalActive, state.GlossaryModalActive, patternInput[0], state.RecommendationFilter, msg.fields[0].fields[0].Id, new CancellableDeferred$1(2, mapToSelectedGame(msg.fields[0].fields[0])), new CancellablePagedDeferred$1(0), state.Mechanics, state.Categories), Cmd_batch(ofArray([Cmd_map((arg0) => (new Msg(0, arg0)), patternInput[1]), Cmd_OfFunc_result(new Msg(1, new Msg_1(12, msg.fields[0].fields[0].MechanicIds, msg.fields[0].fields[0].CategoryIds))), Cmd_OfFunc_result(new Msg(2, new AsyncOperation$1(0)))]))];
    }
    else {
        const patternInput_1 = update_2(msg.fields[0], state.GameSearcher);
        return [new Model(state.MoreInfoModalActive, state.GlossaryModalActive, patternInput_1[0], state.RecommendationFilter, state.SelectedGameId, state.SelectedGame, state.Recommendations, state.Mechanics, state.Categories), Cmd_map((arg0_1) => (new Msg(0, arg0_1)), patternInput_1[1])];
    }
}

export const loadingSpinner = createElement("div", {
    className: "has-text-centered",
    children: Interop_reactApi.Children.toArray([Fa_span(ofArray([new Fa_IconOption(11, "far fa-spinner"), new Fa_IconOption(12), new Fa_IconOption(0, new Fa_ISize(7))]), [])]),
});

export function getRangeString(min, max) {
    if (min === max) {
        return toText(interpolate("%i%P()", [min]));
    }
    else {
        return toText(interpolate("%i%P()-%i%P()", [min, max]));
    }
}

export function renderStars(num) {
    return append_1(map((_arg1) => Fa_stack(singleton_1(new Fa_Stack_Option(1, "star-stack")), [Fa_i(ofArray([new Fa_IconOption(11, "fas fa-star"), new Fa_IconOption(17), new Fa_IconOption(15, "filled-star")]), []), Fa_i(ofArray([new Fa_IconOption(11, "fal fa-star"), new Fa_IconOption(17)]), [])]), toList(rangeDouble(1, 1, num))), map((_arg2) => Fa_stack(singleton_1(new Fa_Stack_Option(1, "star-stack")), [Fa_i(ofArray([new Fa_IconOption(11, "fas fa-star"), new Fa_IconOption(17), new Fa_IconOption(15, "empty-star")]), []), Fa_i(ofArray([new Fa_IconOption(11, "fal fa-star"), new Fa_IconOption(17)]), [])]), toList(rangeDouble(1, 1, 3 - num))));
}

export function renderSelectedGame(g) {
    let thumbnailUrl;
    const matchValue = g.Thumbnail;
    thumbnailUrl = ((matchValue == null) ? "" : matchValue);
    return createElement("div", {
        className: "selected-game",
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let children, children_2, value_44, children_4, value_48;
            return append(singleton(createElement("div", {
                className: "recommendation game",
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: "image-background",
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        className: "image-background-image",
                        style: {
                            backgroundImage: ("url(\u0027" + thumbnailUrl) + "\u0027)",
                        },
                    })]),
                }), createElement("div", {
                    className: "image-container",
                    children: Interop_reactApi.Children.toArray([createElement("img", {
                        className: "game-image",
                        src: thumbnailUrl,
                    })]),
                }), createElement("div", {
                    className: "game-information",
                    children: Interop_reactApi.Children.toArray([(children = ofArray([createElement("span", {
                        className: "game-name",
                        children: g.Name,
                    }), createElement("span", {
                        className: "year-published",
                        children: toText(interpolate(" (%i%P()) ", [g.YearPublished])),
                    }), createElement("a", {
                        href: toText(interpolate("https://boardgamegeek.com/boardgame/%i%P()", [g.Id])),
                        target: "_blank",
                        rel: "noopener",
                        className: "tooltip",
                        ["data-tooltip"]: "BoardGameGeek",
                        children: Interop_reactApi.Children.toArray([Fa_i(singleton_1(new Fa_IconOption(11, "far fa-external-link")), [])]),
                    })]), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    })), createElement("div", {
                        className: "game-breakdown",
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: "game-stats",
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                className: "game-stat",
                                children: Interop_reactApi.Children.toArray([(children_2 = singleton_1(Fa_i(ofArray([new Fa_IconOption(11, "fas fa-users"), new Fa_IconOption(0, new Fa_ISize(3))]), [])), createElement("div", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                                })), (value_44 = toText(interpolate("%s%P() Players", [getRangeString(g.MinPlayers, g.MaxPlayers)])), createElement("div", {
                                    children: [value_44],
                                }))]),
                            }), createElement("div", {
                                className: "game-stat",
                                children: Interop_reactApi.Children.toArray([(children_4 = singleton_1(Fa_i(ofArray([new Fa_IconOption(11, "far fa-stopwatch"), new Fa_IconOption(0, new Fa_ISize(3))]), [])), createElement("div", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                                })), (value_48 = toText(interpolate("%s%P() Min", [getRangeString(g.MinPlayingTime, g.MaxPlayingTime)])), createElement("div", {
                                    children: [value_48],
                                }))]),
                            })]),
                        })]),
                    })]),
                })]),
            })), delay(() => {
                const matchValue_1 = g.TimeTrend;
                switch (matchValue_1.tag) {
                    case 1: {
                        return singleton(createElement("div", {
                            className: "chart",
                            children: Interop_reactApi.Children.toArray([loadingSpinner]),
                        }));
                    }
                    case 2: {
                        return singleton(timeTrend_1(matchValue_1.fields[0]));
                    }
                    default: {
                        return singleton(null);
                    }
                }
            }));
        })))),
    });
}

export function renderRecommendation(r) {
    let children, children_2, value_45, value_49, value_54, children_4, children_6, children_8, value_72, children_10, value_76;
    let thumbnailUrl;
    const matchValue = r.Thumbnail;
    thumbnailUrl = ((matchValue == null) ? "" : matchValue);
    return createElement("div", {
        className: "recommendation game",
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "image-background",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "image-background-image",
                style: {
                    backgroundImage: ("url(\u0027" + thumbnailUrl) + "\u0027)",
                },
            })]),
        }), createElement("div", {
            className: "image-container",
            children: Interop_reactApi.Children.toArray([createElement("img", {
                className: "game-image",
                src: thumbnailUrl,
            })]),
        }), createElement("div", {
            className: "game-information",
            children: Interop_reactApi.Children.toArray([(children = ofArray([createElement("span", {
                className: "game-name",
                children: r.Name,
            }), createElement("span", {
                className: "year-published",
                children: toText(interpolate(" (%i%P()) ", [r.YearPublished])),
            }), viewLink(new Page(0, r.Id), [["className", "replace-game tooltip"], ["data-tooltip", "Like"], ["children", Interop_reactApi.Children.toArray([Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-thumbs-up")), [])])]]), createElement("a", {
                href: toText(interpolate("https://boardgamegeek.com/boardgame/%i%P()", [r.Id])),
                target: "_blank",
                rel: "noopener",
                className: "tooltip",
                ["data-tooltip"]: "BoardGameGeek",
                children: Interop_reactApi.Children.toArray([Fa_i(singleton_1(new Fa_IconOption(11, "far fa-external-link")), [])]),
            })]), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), createElement("div", {
                className: "game-breakdown",
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: "game-scores",
                    children: Interop_reactApi.Children.toArray([(children_2 = ofArray([createElement("span", {
                        children: ["Like score: "],
                    }), (value_45 = toText(interpolate("%.1f%P() ", [r.LikeScore])), createElement("span", {
                        children: [value_45],
                    }))]), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                    })), createElement("div", {
                        className: "game-sub-score",
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            children: ["User score: "],
                        }), (value_49 = toText(interpolate("%.1f%P() ", [r.UserScore])), createElement("span", {
                            children: [value_49],
                        }))]),
                    }), createElement("div", {
                        className: "game-sub-score",
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            children: ["Game score: "],
                        }), (value_54 = toText(interpolate("%.1f%P() ", [r.GameScore])), createElement("span", {
                            children: [value_54],
                        }))]),
                    })]),
                }), createElement("div", {
                    className: "game-stars",
                    children: Interop_reactApi.Children.toArray([(children_4 = toList(delay(() => append(singleton(createElement("span", {
                        className: "star-heading",
                        children: "Strength: ",
                    })), delay(() => renderStars(r.Strength))))), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    })), (children_6 = toList(delay(() => append(singleton(createElement("span", {
                        className: "star-heading",
                        children: "Surety: ",
                    })), delay(() => renderStars(r.Surety))))), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                    }))]),
                }), createElement("div", {
                    className: "game-stats",
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        className: "game-stat",
                        children: Interop_reactApi.Children.toArray([(children_8 = singleton_1(Fa_i(ofArray([new Fa_IconOption(11, "fas fa-users"), new Fa_IconOption(0, new Fa_ISize(3))]), [])), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                        })), (value_72 = toText(interpolate("%s%P() Players", [getRangeString(r.MinPlayers, r.MaxPlayers)])), createElement("div", {
                            children: [value_72],
                        }))]),
                    }), createElement("div", {
                        className: "game-stat",
                        children: Interop_reactApi.Children.toArray([(children_10 = singleton_1(Fa_i(ofArray([new Fa_IconOption(11, "far fa-stopwatch"), new Fa_IconOption(0, new Fa_ISize(3))]), [])), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_10)),
                        })), (value_76 = toText(interpolate("%s%P() Min", [getRangeString(r.MinPlayingTime, r.MaxPlayingTime)])), createElement("div", {
                            children: [value_76],
                        }))]),
                    })]),
                })]),
            })]),
        })]),
    });
}

export function render(model, dispatch) {
    const children_4 = toList(delay(() => {
        let props_5;
        return append(singleton((props_5 = ofArray([["className", "about-bar"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            href: "mailto:admin@trythesegames.com",
            className: "contact-link",
            children: "Contact",
        }), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-small"], ["className", "is-info"], ["onClick", (_arg1) => {
            dispatch(new Msg(9));
        }], ["children", "Glossary"]])))), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-small"], ["className", "is-info"], ["onClick", (_arg2) => {
            dispatch(new Msg(8));
        }], ["children", "About"]])))), viewLinkButton(new Page(1), [["className", "is-small"], ["className", "is-primary"], ["children", "Rankings"]])])]]), createElement("div", createObj(Helpers_combineClasses("container", props_5))))), delay(() => {
            let elms;
            return append(singleton((elms = singleton_1(createElement("h1", createObj(Helpers_combineClasses("title is-1", ofArray([["className", "is-1"], ["children", "If you like this game..."]]))))), createElement("div", {
                className: "container",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))), delay(() => {
                let props_10;
                return append(singleton((props_10 = ofArray([["className", "search-box"], ["children", Interop_reactApi.Children.toArray([render_1(model.GameSearcher, (arg) => {
                    dispatch(new Msg(0, arg));
                })])]]), createElement("div", createObj(Helpers_combineClasses("container", props_10))))), delay(() => {
                    let matchValue, elms_1;
                    return append((matchValue = model.SelectedGame, (matchValue.tag === 1) ? singleton(createElement("div", {
                        className: "container",
                        children: Interop_reactApi.Children.toArray([loadingSpinner]),
                    })) : ((matchValue.tag === 0) ? singleton(null) : singleton((elms_1 = singleton_1(renderSelectedGame(matchValue.fields[0])), createElement("div", {
                        className: "container",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                    }))))), delay(() => {
                        let props_16;
                        return append(singleton((props_16 = ofArray([["className", "recommendations-heading"], ["children", Interop_reactApi.Children.toArray([createElement("h1", createObj(Helpers_combineClasses("title is-1", ofArray([["className", "is-1"], ["children", "try these games..."]]))))])]]), createElement("div", createObj(Helpers_combineClasses("container", props_16))))), delay(() => {
                            let matchValue_1, elms_3, props_27, props_23, props_25;
                            return append((matchValue_1 = model.Recommendations, (matchValue_1.tag === 2) ? singleton((elms_3 = singleton_1((props_27 = ofArray([["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([(props_23 = ofArray([["className", "is-three-fifths-fullhd"], ["className", "is-two-thirds-tablet"], ["className", "is-full-mobile"], ["className", "games-column"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                const matchValue_2 = model.Recommendations;
                                return (matchValue_2.tag === 1) ? singleton(loadingSpinner) : ((matchValue_2.tag === 3) ? (isEmpty(matchValue_2.fields[0]) ? singleton(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-info"], ["children", "Not enough data to make a recommendation"]]))))) : (matchValue_2.fields[1] ? append(map((r_2) => renderRecommendation(r_2), matchValue_2.fields[0]), delay(() => singleton(createElement("div", {
                                    className: "has-text-centered",
                                    children: Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg3) => {
                                        dispatch(new Msg(2, new AsyncOperation$1(0)));
                                    }], ["children", "Load More"]]))))]),
                                })))) : map((r_1) => renderRecommendation(r_1), matchValue_2.fields[0]))) : ((matchValue_2.tag === 2) ? append(map((r) => renderRecommendation(r), matchValue_2.fields[0]), delay(() => singleton(loadingSpinner))) : singleton(null)));
                            }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_23)))), (props_25 = ofArray([["className", "filter-column"], ["className", "is-two-fifths-fullhd"], ["className", "is-one-third-tablet"], ["children", Interop_reactApi.Children.toArray([render_2(model.RecommendationFilter, (arg_1) => {
                                dispatch(new Msg(1, arg_1));
                            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_25))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_27))))), createElement("div", {
                                className: "container",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                            }))) : ((matchValue_1.tag === 3) ? singleton((elms_3 = singleton_1((props_27 = ofArray([["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([(props_23 = ofArray([["className", "is-three-fifths-fullhd"], ["className", "is-two-thirds-tablet"], ["className", "is-full-mobile"], ["className", "games-column"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                const matchValue_2 = model.Recommendations;
                                return (matchValue_2.tag === 1) ? singleton(loadingSpinner) : ((matchValue_2.tag === 3) ? (isEmpty(matchValue_2.fields[0]) ? singleton(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-info"], ["children", "Not enough data to make a recommendation"]]))))) : (matchValue_2.fields[1] ? append(map((r_2) => renderRecommendation(r_2), matchValue_2.fields[0]), delay(() => singleton(createElement("div", {
                                    className: "has-text-centered",
                                    children: Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg3) => {
                                        dispatch(new Msg(2, new AsyncOperation$1(0)));
                                    }], ["children", "Load More"]]))))]),
                                })))) : map((r_1) => renderRecommendation(r_1), matchValue_2.fields[0]))) : ((matchValue_2.tag === 2) ? append(map((r) => renderRecommendation(r), matchValue_2.fields[0]), delay(() => singleton(loadingSpinner))) : singleton(null)));
                            }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_23)))), (props_25 = ofArray([["className", "filter-column"], ["className", "is-two-fifths-fullhd"], ["className", "is-one-third-tablet"], ["children", Interop_reactApi.Children.toArray([render_2(model.RecommendationFilter, (arg_1) => {
                                dispatch(new Msg(1, arg_1));
                            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_25))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_27))))), createElement("div", {
                                className: "container",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                            }))) : ((matchValue_1.tag === 0) ? singleton(null) : singleton((elms_3 = singleton_1((props_27 = ofArray([["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([(props_23 = ofArray([["className", "is-three-fifths-fullhd"], ["className", "is-two-thirds-tablet"], ["className", "is-full-mobile"], ["className", "games-column"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                const matchValue_2 = model.Recommendations;
                                return (matchValue_2.tag === 1) ? singleton(loadingSpinner) : ((matchValue_2.tag === 3) ? (isEmpty(matchValue_2.fields[0]) ? singleton(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-info"], ["children", "Not enough data to make a recommendation"]]))))) : (matchValue_2.fields[1] ? append(map((r_2) => renderRecommendation(r_2), matchValue_2.fields[0]), delay(() => singleton(createElement("div", {
                                    className: "has-text-centered",
                                    children: Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg3) => {
                                        dispatch(new Msg(2, new AsyncOperation$1(0)));
                                    }], ["children", "Load More"]]))))]),
                                })))) : map((r_1) => renderRecommendation(r_1), matchValue_2.fields[0]))) : ((matchValue_2.tag === 2) ? append(map((r) => renderRecommendation(r), matchValue_2.fields[0]), delay(() => singleton(loadingSpinner))) : singleton(null)));
                            }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_23)))), (props_25 = ofArray([["className", "filter-column"], ["className", "is-two-fifths-fullhd"], ["className", "is-one-third-tablet"], ["children", Interop_reactApi.Children.toArray([render_2(model.RecommendationFilter, (arg_1) => {
                                dispatch(new Msg(1, arg_1));
                            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_25))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_27))))), createElement("div", {
                                className: "container",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                            })))))), delay(() => append(singleton(moreInfoModal(model.MoreInfoModalActive, (_arg4) => {
                                dispatch(new Msg(8));
                            })), delay(() => singleton(recommendationsGlossaryModal(model.GlossaryModalActive, (_arg5) => {
                                dispatch(new Msg(9));
                            }))))));
                        }));
                    }));
                }));
            }));
        }));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    });
}

