import { Record, Union } from "./.fable/fable-library.3.1.12/Types.js";
import { render as render_2, Msg as Msg_1, update as update_1, init as init_1, Msg$reflection as Msg$reflection_1, Model$reflection as Model$reflection_1 } from "./Recommendations/RecommendationsPage.fs.js";
import { render as render_1, update as update_2, init as init_2, Msg$reflection as Msg$reflection_2, Model$reflection as Model$reflection_2 } from "./Ranking/RankingPage.fs.js";
import { record_type, union_type } from "./.fable/fable-library.3.1.12/Reflection.js";
import { urlParser, Page, Page$reflection } from "./Pages.fs.js";
import { Cmd_OfFunc_result, Cmd_none, Cmd_map } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { ProgramModule_mkProgram, ProgramModule_run } from "./.fable/Fable.Elmish.3.1.0/program.fs.js";
import { Program_withReactSynchronous } from "./.fable/Fable.Elmish.React.3.0.1/react.fs.js";
import { ProgramModule_toNavigable } from "./.fable/Fable.Elmish.Browser.3.0.4/navigation.fs.js";

export class PageModel extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["RecommendationsPageModel", "RankingPageModel"];
    }
}

export function PageModel$reflection() {
    return union_type("Client.App.PageModel", [], PageModel, () => [[["Item", Model$reflection_1()]], [["Item", Model$reflection_2()]]]);
}

export class Model extends Record {
    constructor(Route, PageModel) {
        super();
        this.Route = Route;
        this.PageModel = PageModel;
    }
}

export function Model$reflection() {
    return record_type("Client.App.Model", [], Model, () => [["Route", Page$reflection()], ["PageModel", PageModel$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["RecommendationsPageMsg", "RankingPageMsg"];
    }
}

export function Msg$reflection() {
    return union_type("Client.App.Msg", [], Msg, () => [[["Item", Msg$reflection_1()]], [["Item", Msg$reflection_2()]]]);
}

export function init(page) {
    if (page == null) {
        const patternInput_3 = init_1(void 0);
        return [new Model(new Page(0, void 0), new PageModel(0, patternInput_3[0])), Cmd_map((arg0_2) => (new Msg(0, arg0_2)), patternInput_3[1])];
    }
    else {
        const p = page;
        let patternInput_2;
        if (p.tag === 1) {
            const patternInput_1 = init_2();
            patternInput_2 = [new PageModel(1, patternInput_1[0]), Cmd_map((arg0_1) => (new Msg(1, arg0_1)), patternInput_1[1])];
        }
        else {
            const patternInput = init_1(p.fields[0]);
            patternInput_2 = [new PageModel(0, patternInput[0]), Cmd_map((arg0) => (new Msg(0, arg0)), patternInput[1])];
        }
        return [new Model(p, patternInput_2[0]), patternInput_2[1]];
    }
}

export function update(msg, state) {
    const matchValue = [msg, state.PageModel];
    let pattern_matching_result, m, msg_1, m_2, msg_2;
    if (matchValue[0].tag === 1) {
        if (matchValue[1].tag === 1) {
            pattern_matching_result = 1;
            m_2 = matchValue[1].fields[0];
            msg_2 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else if (matchValue[1].tag === 0) {
        pattern_matching_result = 0;
        m = matchValue[1].fields[0];
        msg_1 = matchValue[0].fields[0];
    }
    else {
        pattern_matching_result = 2;
    }
    switch (pattern_matching_result) {
        case 0: {
            const patternInput = update_1(msg_1, m);
            return [new Model(state.Route, new PageModel(0, patternInput[0])), Cmd_map((arg0) => (new Msg(0, arg0)), patternInput[1])];
        }
        case 1: {
            const patternInput_1 = update_2(msg_2, m_2);
            return [new Model(state.Route, new PageModel(1, patternInput_1[0])), Cmd_map((arg0_1) => (new Msg(1, arg0_1)), patternInput_1[1])];
        }
        case 2: {
            return [state, Cmd_none()];
        }
    }
}

export function urlUpdate(result, model) {
    if (result == null) {
        return [model, Cmd_none()];
    }
    else if (result.tag === 1) {
        const patternInput_2 = init_2();
        return [new Model(model.Route, new PageModel(1, patternInput_2[0])), Cmd_map((arg0_2) => (new Msg(1, arg0_2)), patternInput_2[1])];
    }
    else if (result.fields[0] != null) {
        const id = result.fields[0] | 0;
        window.scrollTo(0,0);;
        if (model.PageModel.tag === 0) {
            return [model, Cmd_OfFunc_result(new Msg(0, new Msg_1(6, id)))];
        }
        else {
            const patternInput_1 = init_1(id);
            return [new Model(model.Route, new PageModel(0, patternInput_1[0])), Cmd_map((arg0_1) => (new Msg(0, arg0_1)), patternInput_1[1])];
        }
    }
    else {
        const patternInput = init_1(void 0);
        return [new Model(model.Route, new PageModel(0, patternInput[0])), Cmd_map((arg0) => (new Msg(0, arg0)), patternInput[1])];
    }
}

export function render(model, dispatch) {
    const matchValue = model.PageModel;
    if (matchValue.tag === 1) {
        return render_1(matchValue.fields[0], (arg_1) => {
            dispatch(new Msg(1, arg_1));
        });
    }
    else {
        return render_2(matchValue.fields[0], (arg) => {
            dispatch(new Msg(0, arg));
        });
    }
}

ProgramModule_run(Program_withReactSynchronous("elmish-app", ProgramModule_toNavigable((location) => urlParser(location), (result, model_1) => urlUpdate(result, model_1), ProgramModule_mkProgram((page) => init(page), (msg, state) => update(msg, state), (model, dispatch) => render(model, dispatch)))));

