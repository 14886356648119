import { Types_RequestProperties } from "./.fable/Fable.Fetch.2.3.1/Fetch.fs.js";
import { singleton } from "./.fable/fable-library.3.1.12/List.js";
import { keyValueList } from "./.fable/fable-library.3.1.12/MapUtil.js";
import { reject } from "./.fable/Fable.Promise.2.2.2/Promise.fs.js";
import { interpolate, toText } from "./.fable/fable-library.3.1.12/String.js";
import { fromString } from "./.fable/Thoth.Json.6.0.0/Decode.fs.js";

export function fetchAs(url, decoder) {
    let pr_1, props;
    const abortController = new AbortController();
    return [(pr_1 = fetch(url, (props = singleton(new Types_RequestProperties(11, abortController.signal)), keyValueList(props, 1))), pr_1.then(((response) => {
        if (!(response.ok)) {
            return reject(toText(interpolate("%d%P() %s%P() for URL %s%P()", [response.status, response.statusText, response.url])));
        }
        else {
            const pr = response.text();
            return pr.then(((json) => {
                const matchValue = fromString(decoder, json);
                if (matchValue.tag === 1) {
                    return reject(matchValue.fields[0]);
                }
                else {
                    return Promise.resolve(matchValue.fields[0]);
                }
            }));
        }
    }))), abortController.abort];
}

