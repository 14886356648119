import { React_memo_62A0F746, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.49.0/React.fs.js";
import { toArray, empty, singleton, append, delay, toList } from "./.fable/fable-library.3.1.12/Seq.js";
import { floatTryParse } from "./Helpers.fs.js";
import { createElement } from "react";
import * as react from "react";
import { min as min_1, comparePrimitives, createObj } from "./.fable/fable-library.3.1.12/Util.js";
import { Helpers_combineClasses } from "./.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { zip, map as map_2, toArray as toArray_1, replicate, ofArray, cons } from "./.fable/fable-library.3.1.12/List.js";
import { fromDate } from "./.fable/fable-library.3.1.12/DateOffset.js";
import { toNumber, fromNumber } from "./.fable/fable-library.3.1.12/Long.js";
import { days, fromSeconds } from "./.fable/fable-library.3.1.12/TimeSpan.js";
import { englishUK, localFormat } from "./DateFormat.fs.js";
import { bind, map, defaultArg } from "./.fable/fable-library.3.1.12/Option.js";
import { max, map as map_1, min } from "./.fable/fable-library.3.1.12/Array.js";
import { Interop_reactApi } from "./.fable/Feliz.1.49.0/Interop.fs.js";
import { YAxis, XAxis, CartesianGrid, Line, LineChart, ResponsiveContainer } from "recharts";
import { Props_Cartesian, Props_Margin, Props_Chart } from "./.fable/Fable.Recharts.1.1.2/Fable.Recharts.fs.js";
import { keyValueList } from "./.fable/fable-library.3.1.12/MapUtil.js";
import { rangeDouble } from "./.fable/fable-library.3.1.12/Range.js";

export function Input_NonEmptyNumber(props) {
    const valueStr = props.Value.toString();
    const patternInput = useFeliz_React__React_useState_Static_1505(valueStr);
    const updateInternalValue = patternInput[1];
    const internalValue = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const updateEditing = patternInput_1[1];
    useReact_useEffect_Z101E1A95(() => {
        if (!patternInput_1[0]) {
            updateInternalValue(valueStr);
        }
    }, [valueStr]);
    const props_1 = toList(delay(() => append(singleton(["id", props.Id]), delay(() => append(singleton(["value", internalValue]), delay(() => append(singleton(["onChange", (ev) => {
        const v = ev.target.value;
        updateInternalValue(v);
        props.OnChange(floatTryParse(v));
    }]), delay(() => append(singleton(["onBlur", (_arg1) => {
        if (internalValue !== valueStr) {
            updateInternalValue(valueStr);
        }
        updateEditing(false);
    }]), delay(() => append(singleton(["onFocus", (_arg2) => {
        updateEditing(true);
    }]), delay(() => {
        const matchValue = props.Step;
        if (matchValue == null) {
            return empty();
        }
        else {
            return singleton(["step", matchValue]);
        }
    }))))))))))));
    return createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_1))));
}

export const timeTrend = React_memo_62A0F746((props) => {
    const unixTimeOr0 = (date) => {
        let copyOfStruct = fromDate(date);
        return fromNumber(copyOfStruct.getTime() / 1000);
    };
    const toUnixDay = (unixSeconds) => {
        let copyOfStruct_1;
        return ((copyOfStruct_1 = fromSeconds(toNumber(unixSeconds)), days(copyOfStruct_1)) / 1000) - 16;
    };
    const formatDate = (d) => localFormat(englishUK(), "MMM yyyy", d);
    return defaultArg(map((trendData) => {
        let props_10, props_8;
        const lowerBound = Math.floor(min(map_1((x_2) => x_2.rating, trendData, Float64Array), {
            Compare: (x_3, y_1) => comparePrimitives(x_3, y_1),
        }) * 2) / 2;
        const upperBound = min_1((x_1, y) => comparePrimitives(x_1, y), Math.ceil(max(map_1((x_6) => x_6.rating, trendData, Float64Array), {
            Compare: (x_7, y_2) => comparePrimitives(x_7, y_2),
        }) * 2) / 2, 10);
        return createElement("div", {
            className: "chart",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "time-trend-heading",
                children: "Average rating time trend",
            }), react.createElement(ResponsiveContainer, {
                aspect: 2.5,
            }, (props_10 = ofArray([new Props_Chart(4, trendData), new Props_Chart(5, new Props_Margin(10, 5, 35, 5))]), react.createElement(LineChart, keyValueList(props_10, 1), react.createElement(Line, {
                dot: false,
                type: "monotone",
                dataKey: "rating",
                stroke: "orange",
                strokeWidth: "2.0",
                isAnimationActive: false,
            }), react.createElement(CartesianGrid, {
                stroke: "#ccc",
                strokeDasharray: "5 5",
            }), react.createElement(XAxis, {
                dataKey: "name",
                interval: 398,
                tickSize: 10,
                tickMargin: 10,
            }), (props_8 = ofArray([new Props_Cartesian(48, new Float64Array([lowerBound, upperBound])), new Props_Cartesian(49, "preserveStartEnd"), new Props_Cartesian(46, toArray(rangeDouble(lowerBound, 0.5, upperBound))), new Props_Cartesian(38, true), new Props_Cartesian(42, 10), new Props_Cartesian(29, 35)]), react.createElement(YAxis, keyValueList(props_8, 1))))))]),
        });
    }, bind((coeffs) => {
        const coeffsF = map_1((value) => value, coeffs, Float64Array);
        const startDate = map(unixTimeOr0, props.RatingTrendStartDate);
        const endDate = map(unixTimeOr0, props.RatingTrendEndDate);
        const startValue = map(toUnixDay, startDate);
        const endValue = map(toUnixDay, endDate);
        let interval;
        const matchValue = [startValue, endValue];
        let pattern_matching_result, end$0027, start;
        if (matchValue[0] != null) {
            if (matchValue[1] != null) {
                pattern_matching_result = 0;
                end$0027 = matchValue[1];
                start = matchValue[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                interval = ((end$0027 - start) / 400);
                break;
            }
            case 1: {
                interval = (void 0);
                break;
            }
        }
        const labels = toList(delay(() => {
            let matchValue_1;
            return append((matchValue_1 = props.RatingTrendStartDate, (matchValue_1 == null) ? singleton("") : singleton(formatDate(matchValue_1))), delay(() => append(replicate(398, ""), delay(() => {
                const matchValue_2 = props.RatingTrendEndDate;
                return (matchValue_2 == null) ? singleton("") : singleton(formatDate(matchValue_2));
            }))));
        }));
        const matchValue_3 = [startValue, endValue, interval];
        let pattern_matching_result_1, end$0027_1, i, start_1;
        if (matchValue_3[0] != null) {
            if (matchValue_3[1] != null) {
                if (matchValue_3[2] != null) {
                    pattern_matching_result_1 = 0;
                    end$0027_1 = matchValue_3[1];
                    i = matchValue_3[2];
                    start_1 = matchValue_3[0];
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                return toArray_1(map_2((tupledArg) => ({
                    name: tupledArg[0],
                    rating: tupledArg[1],
                }), zip(labels, map_2((x) => ((((coeffsF[0] + (coeffsF[1] * x)) + (coeffsF[2] * Math.pow(x, 2))) + (coeffsF[3] * Math.pow(x, 3))) + (coeffsF[4] * Math.pow(x, 4))), toList(rangeDouble(start_1, i, end$0027_1))))));
            }
            case 1: {
                return void 0;
            }
        }
    }, props.RatingTrendCoefficients)), null);
});

