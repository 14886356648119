import { toString as toString_1, Record } from "./.fable/fable-library.3.1.12/Types.js";
import { enum_type, int32_type, record_type, string_type } from "./.fable/fable-library.3.1.12/Reflection.js";
import { getCharAtIndex, substring, padLeft, printf, toFail } from "./.fable/fable-library.3.1.12/String.js";
import { comparePrimitives, max } from "./.fable/fable-library.3.1.12/Util.js";
import { replace } from "./.fable/fable-library.3.1.12/RegExp.js";
import { year as year_1, second, month as month_2, minute, hour, dayOfWeek, day as day_2 } from "./.fable/fable-library.3.1.12/Date.js";

export class Months extends Record {
    constructor(January, February, March, April, May, June, July, August, September, October, November, December) {
        super();
        this.January = January;
        this.February = February;
        this.March = March;
        this.April = April;
        this.May = May;
        this.June = June;
        this.July = July;
        this.August = August;
        this.September = September;
        this.October = October;
        this.November = November;
        this.December = December;
    }
}

export function Months$reflection() {
    return record_type("Format.Months", [], Months, () => [["January", string_type], ["February", string_type], ["March", string_type], ["April", string_type], ["May", string_type], ["June", string_type], ["July", string_type], ["August", string_type], ["September", string_type], ["October", string_type], ["November", string_type], ["December", string_type]]);
}

export class DaysOfWeek extends Record {
    constructor(Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday) {
        super();
        this.Monday = Monday;
        this.Tuesday = Tuesday;
        this.Wednesday = Wednesday;
        this.Thursday = Thursday;
        this.Friday = Friday;
        this.Saturday = Saturday;
        this.Sunday = Sunday;
    }
}

export function DaysOfWeek$reflection() {
    return record_type("Format.DaysOfWeek", [], DaysOfWeek, () => [["Monday", string_type], ["Tuesday", string_type], ["Wednesday", string_type], ["Thursday", string_type], ["Friday", string_type], ["Saturday", string_type], ["Sunday", string_type]]);
}

export class Date$ extends Record {
    constructor(Months, AbbreviatedMonths, Days, AbbreviatedDays, DefaultFormat, FirstDayOfTheWeek) {
        super();
        this.Months = Months;
        this.AbbreviatedMonths = AbbreviatedMonths;
        this.Days = Days;
        this.AbbreviatedDays = AbbreviatedDays;
        this.DefaultFormat = DefaultFormat;
        this.FirstDayOfTheWeek = (FirstDayOfTheWeek | 0);
    }
}

export function Date$$reflection() {
    return record_type("Format.Date", [], Date$, () => [["Months", Months$reflection()], ["AbbreviatedMonths", Months$reflection()], ["Days", DaysOfWeek$reflection()], ["AbbreviatedDays", DaysOfWeek$reflection()], ["DefaultFormat", string_type], ["FirstDayOfTheWeek", enum_type("System.DayOfWeek", int32_type, [["Sunday", 0], ["Monday", 1], ["Tuesday", 2], ["Wednesday", 3], ["Thursday", 4], ["Friday", 5], ["Saturday", 6]])]]);
}

export class Time extends Record {
    constructor(AM, PM) {
        super();
        this.AM = AM;
        this.PM = PM;
    }
}

export function Time$reflection() {
    return record_type("Format.Time", [], Time, () => [["AM", string_type], ["PM", string_type]]);
}

export class Localization extends Record {
    constructor(Date$, Time) {
        super();
        this.Date = Date$;
        this.Time = Time;
    }
}

export function Localization$reflection() {
    return record_type("Format.Localization", [], Localization, () => [["Date", Date$$reflection()], ["Time", Time$reflection()]]);
}

export function englishUK() {
    return new Localization(new Date$(new Months("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"), new Months("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"), new DaysOfWeek("Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"), new DaysOfWeek("Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"), "d/M/yyyy", 1), new Time("AM", "PM"));
}

export function fromDayOfWeek(local, day) {
    switch (day) {
        case 0: {
            return local.Sunday;
        }
        case 1: {
            return local.Monday;
        }
        case 2: {
            return local.Tuesday;
        }
        case 3: {
            return local.Wednesday;
        }
        case 4: {
            return local.Thursday;
        }
        case 5: {
            return local.Friday;
        }
        case 6: {
            return local.Saturday;
        }
        default: {
            return toFail(printf("Not a valid day of week: %A"))(day);
        }
    }
}

export function mod12(x) {
    if ((x === 12) ? true : (x === 0)) {
        return 12;
    }
    else {
        return (x % 12) | 0;
    }
}

export function fromMonth(local, month) {
    switch (month) {
        case 1: {
            return local.January;
        }
        case 2: {
            return local.February;
        }
        case 3: {
            return local.March;
        }
        case 4: {
            return local.April;
        }
        case 5: {
            return local.May;
        }
        case 6: {
            return local.June;
        }
        case 7: {
            return local.July;
        }
        case 8: {
            return local.August;
        }
        case 9: {
            return local.September;
        }
        case 10: {
            return local.October;
        }
        case 11: {
            return local.November;
        }
        case 12: {
            return local.December;
        }
        default: {
            return toFail(printf("Not a valid month rank: %i"))(month);
        }
    }
}

export function toString(x) {
    return toString_1(x);
}

export const padWith = (c) => ((arg) => padLeft(arg, 2, c));

export function takeLastChars(count, str) {
    return substring(str, max((x, y) => comparePrimitives(x, y), 0, str.length - count));
}

export function localFormat(local, formatString, date) {
    return replace(formatString, "(d{1,4})|(h{1,2})|(H{1,2})|(m{1,2})|(M{1,4})|(s{1,2})|(t{1,2})|(y{1,5})|(\\\\.?)", (token) => {
        const symbol = token[0];
        if (((symbol || "").indexOf("\\") === 0) ? ((symbol || "").length === 2) : false) {
            return substring(symbol || "", 1);
        }
        else {
            const matchValue = symbol || "";
            switch (matchValue) {
                case "d": {
                    return toString(day_2(date));
                }
                case "dd": {
                    return padWith("0")(toString(day_2(date)));
                }
                case "ddd": {
                    return fromDayOfWeek(local.Date.AbbreviatedDays, dayOfWeek(date));
                }
                case "dddd": {
                    return fromDayOfWeek(local.Date.Days, dayOfWeek(date));
                }
                case "h": {
                    return toString(mod12(hour(date)));
                }
                case "hh": {
                    return padWith("0")(toString(mod12(hour(date))));
                }
                case "H": {
                    return toString(hour(date));
                }
                case "HH": {
                    return padWith("0")(toString(hour(date)));
                }
                case "m": {
                    return toString(minute(date));
                }
                case "mm": {
                    return padWith("0")(toString(minute(date)));
                }
                case "M": {
                    return toString(month_2(date));
                }
                case "MM": {
                    return padWith("0")(toString(month_2(date)));
                }
                case "MMM": {
                    return fromMonth(local.Date.AbbreviatedMonths, month_2(date));
                }
                case "MMMM": {
                    return fromMonth(local.Date.Months, month_2(date));
                }
                case "s": {
                    return toString(second(date));
                }
                case "ss": {
                    return padWith("0")(toString(second(date)));
                }
                case "t": {
                    try {
                        return (hour(date) < 12) ? substring(local.Time.AM, 0, 1) : substring(local.Time.PM, 0, 1);
                    }
                    catch (matchValue_1) {
                        return "";
                    }
                }
                case "tt": {
                    return (hour(date) < 12) ? local.Time.AM : local.Time.PM;
                }
                case "y": {
                    const year = takeLastChars(2, toString(year_1(date)));
                    return (getCharAtIndex(year, 0) === "0") ? substring(year, 1) : year;
                }
                case "yy": {
                    return padWith("0")(takeLastChars(2, toString(year_1(date))));
                }
                case "yyy": {
                    return padLeft(toString(year_1(date)), 3, "0");
                }
                case "yyyy": {
                    return padLeft(toString(year_1(date)), 4, "0");
                }
                case "yyyyy": {
                    return padLeft(toString(year_1(date)), 5, "0");
                }
                default: {
                    return toFail(printf("The token %s is not implemented. Please report it"))(matchValue);
                }
            }
        }
    });
}

