import { Union, Record } from "../.fable/fable-library.3.1.12/Types.js";
import { TimeTrendData, NewGameAdjustment, RankingTimePeriod, RankingQuery, Page$1$reflection, Category$reflection, Mechanic$reflection, RankedBoardGame$reflection } from "../../TryTheseGames.Website.Shared/Shared.fs.js";
import { union_type, class_type, record_type, bool_type, list_type } from "../.fable/fable-library.3.1.12/Reflection.js";
import { Float_toFixed, Deferred$1, CancellablePagedDeferred$1, AsyncOperation$1, toQueryString, AsyncOperation$1$reflection, Deferred$1$reflection, CancellablePagedDeferred$1$reflection } from "../Helpers.fs.js";
import { rankingFilter, RankingFilterModel, RankingFilterModel$reflection } from "./RankingFilter.fs.js";
import { Auto_generateBoxedDecoder_79988AEF } from "../.fable/Thoth.Json.6.0.0/Decode.fs.js";
import { fetchAs } from "../FetchHelpers.fs.js";
import { join, interpolate, toText } from "../.fable/fable-library.3.1.12/String.js";
import { createObj, uncurry } from "../.fable/fable-library.3.1.12/Util.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch, Cmd_OfPromise_either } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { empty, map, isEmpty, singleton, length, append, ofArray } from "../.fable/fable-library.3.1.12/List.js";
import { defaultArg, some } from "../.fable/fable-library.3.1.12/Option.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.49.0/Interop.fs.js";
import { Fa_i, Fa_ISize, Fa_IconOption, Fa_span } from "../.fable/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { viewLinkButton, viewLink } from "../PushStateLink.fs.js";
import { Page } from "../Pages.fs.js";
import { timeTrend } from "../Components.fs.js";
import { singleton as singleton_1, append as append_1, delay, toList } from "../.fable/fable-library.3.1.12/Seq.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { rankingsGlossaryModal } from "../Modals.fs.js";

export class Model extends Record {
    constructor(RankedBoardGames, Filter, MobileFilterOpen, Mechanics, Categories, GlossaryModalActive) {
        super();
        this.RankedBoardGames = RankedBoardGames;
        this.Filter = Filter;
        this.MobileFilterOpen = MobileFilterOpen;
        this.Mechanics = Mechanics;
        this.Categories = Categories;
        this.GlossaryModalActive = GlossaryModalActive;
    }
}

export function Model$reflection() {
    return record_type("Client.Ranking.RankingPage.Model", [], Model, () => [["RankedBoardGames", CancellablePagedDeferred$1$reflection(list_type(RankedBoardGame$reflection()))], ["Filter", RankingFilterModel$reflection()], ["MobileFilterOpen", bool_type], ["Mechanics", Deferred$1$reflection(list_type(Mechanic$reflection()))], ["Categories", Deferred$1$reflection(list_type(Category$reflection()))], ["GlossaryModalActive", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadRankedGames", "LoadMechanics", "LoadCategories", "UpdateFilter", "ApplyFilter", "ToggleMobileFilter", "ToggleGlossaryModal", "LogError"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Ranking.RankingPage.Msg", [], Msg, () => [[["Item", AsyncOperation$1$reflection(Page$1$reflection(RankedBoardGame$reflection()))]], [["Item", AsyncOperation$1$reflection(list_type(Mechanic$reflection()))]], [["Item", AsyncOperation$1$reflection(list_type(Category$reflection()))]], [["Item", RankingFilterModel$reflection()]], [], [], [], [["Item", class_type("System.Exception")]]]);
}

export const rankedGameDecoder = Auto_generateBoxedDecoder_79988AEF(Page$1$reflection(RankedBoardGame$reflection()), void 0, void 0);

export function getGamesCmd(query, skip) {
    const patternInput = fetchAs(toText(interpolate("/api/ranked-games%s%P()", [toQueryString(new RankingQuery(query.TimePeriod, query.NewGameAdjustment, query.PseudoRaters, query.PlayerCount, query.MinPlayTime, query.MaxPlayTime, query.MinPublishYear, query.MaxPublishYear, query.HasMechanic, query.HasCategory, skip), [])])), uncurry(2, rankedGameDecoder));
    return [Cmd_OfPromise_either(() => patternInput[0], void 0, (arg) => (new Msg(0, new AsyncOperation$1(1, arg))), (arg0_2) => (new Msg(7, arg0_2))), patternInput[1]];
}

export const emptyFilter = new RankingFilterModel(new RankingTimePeriod(2), new NewGameAdjustment(3), 1000, void 0, void 0, void 0, void 0, void 0, void 0, void 0);

export const mechanicsDecoder = Auto_generateBoxedDecoder_79988AEF(list_type(Mechanic$reflection()), void 0, void 0);

export function getMechanicsCmd() {
    const patternInput = fetchAs("/api/mechanics", uncurry(2, mechanicsDecoder));
    return Cmd_OfPromise_either(() => patternInput[0], void 0, (arg) => (new Msg(1, new AsyncOperation$1(1, arg))), (arg0_2) => (new Msg(7, arg0_2)));
}

export const categoriesDecoder = Auto_generateBoxedDecoder_79988AEF(list_type(Category$reflection()), void 0, void 0);

export function getCategoriesCmd() {
    const patternInput = fetchAs("/api/categories", uncurry(2, categoriesDecoder));
    return Cmd_OfPromise_either(() => patternInput[0], void 0, (arg) => (new Msg(2, new AsyncOperation$1(1, arg))), (arg0_2) => (new Msg(7, arg0_2)));
}

export function init() {
    return [new Model(new CancellablePagedDeferred$1(0), emptyFilter, false, new Deferred$1(0), new Deferred$1(0), false), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(0, new AsyncOperation$1(0))), Cmd_OfFunc_result(new Msg(1, new AsyncOperation$1(0))), Cmd_OfFunc_result(new Msg(2, new AsyncOperation$1(0)))]))];
}

export function update(msg, state) {
    let matchValue_1, matchValue_2;
    if (msg.tag === 3) {
        return [new Model(state.RankedBoardGames, msg.fields[0], state.MobileFilterOpen, state.Mechanics, state.Categories, state.GlossaryModalActive), Cmd_none()];
    }
    else if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            return [new Model(state.RankedBoardGames, state.Filter, state.MobileFilterOpen, new Deferred$1(2, msg.fields[0].fields[0]), state.Categories, state.GlossaryModalActive), Cmd_none()];
        }
        else {
            return [new Model(state.RankedBoardGames, state.Filter, state.MobileFilterOpen, new Deferred$1(1), state.Categories, state.GlossaryModalActive), getMechanicsCmd()];
        }
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            return [new Model(state.RankedBoardGames, state.Filter, state.MobileFilterOpen, state.Mechanics, new Deferred$1(2, msg.fields[0].fields[0]), state.GlossaryModalActive), Cmd_none()];
        }
        else {
            return [new Model(state.RankedBoardGames, state.Filter, state.MobileFilterOpen, state.Mechanics, new Deferred$1(1), state.GlossaryModalActive), getCategoriesCmd()];
        }
    }
    else if (msg.tag === 5) {
        return [new Model(state.RankedBoardGames, state.Filter, !state.MobileFilterOpen, state.Mechanics, state.Categories, state.GlossaryModalActive), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [new Model(state.RankedBoardGames, state.Filter, state.MobileFilterOpen, state.Mechanics, state.Categories, !state.GlossaryModalActive), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new Model(new CancellablePagedDeferred$1(0), state.Filter, state.MobileFilterOpen, state.Mechanics, state.Categories, state.GlossaryModalActive), Cmd_OfFunc_result(new Msg(0, new AsyncOperation$1(0)))];
    }
    else if (msg.tag === 7) {
        console.error(some(msg.fields[0]));
        return [state, Cmd_none()];
    }
    else if (msg.fields[0].tag === 1) {
        let items;
        const matchValue_3 = state.RankedBoardGames;
        let pattern_matching_result, rs_1;
        switch (matchValue_3.tag) {
            case 1: {
                pattern_matching_result = 0;
                break;
            }
            case 2: {
                pattern_matching_result = 1;
                rs_1 = matchValue_3.fields[0];
                break;
            }
            case 3: {
                pattern_matching_result = 1;
                rs_1 = matchValue_3.fields[0];
                break;
            }
            default: pattern_matching_result = 0}
        switch (pattern_matching_result) {
            case 0: {
                items = msg.fields[0].fields[0].Items;
                break;
            }
            case 1: {
                items = append(rs_1, msg.fields[0].fields[0].Items);
                break;
            }
        }
        return [new Model(new CancellablePagedDeferred$1(3, items, length(items) < msg.fields[0].fields[0].TotalCount), state.Filter, state.MobileFilterOpen, state.Mechanics, state.Categories, state.GlossaryModalActive), Cmd_none()];
    }
    else {
        const matchValue = state.RankedBoardGames;
        let pattern_matching_result_1, cancel;
        switch (matchValue.tag) {
            case 1: {
                pattern_matching_result_1 = 0;
                cancel = matchValue.fields[0];
                break;
            }
            case 2: {
                pattern_matching_result_1 = 0;
                cancel = matchValue.fields[1];
                break;
            }
            default: pattern_matching_result_1 = 1}
        switch (pattern_matching_result_1) {
            case 0: {
                cancel();
                break;
            }
        }
        const patternInput = getGamesCmd(state.Filter, (matchValue_1 = state.RankedBoardGames, (matchValue_1.tag === 3) ? length(matchValue_1.fields[0]) : 0));
        const cancel_1 = patternInput[1];
        return [new Model((matchValue_2 = state.RankedBoardGames, (matchValue_2.tag === 1) ? (new CancellablePagedDeferred$1(1, cancel_1)) : ((matchValue_2.tag === 2) ? (new CancellablePagedDeferred$1(1, cancel_1)) : ((matchValue_2.tag === 3) ? (new CancellablePagedDeferred$1(2, matchValue_2.fields[0], cancel_1)) : (new CancellablePagedDeferred$1(1, cancel_1))))), state.Filter, state.MobileFilterOpen, state.Mechanics, state.Categories, state.GlossaryModalActive), patternInput[0]];
    }
}

export function getRangeString(min, max) {
    if (min === max) {
        return toText(interpolate("%i%P()", [min]));
    }
    else {
        return toText(interpolate("%i%P()-%i%P()", [min, max]));
    }
}

export const loadingSpinner = createElement("div", {
    className: "has-text-centered",
    children: Interop_reactApi.Children.toArray([Fa_span(ofArray([new Fa_IconOption(11, "far fa-spinner"), new Fa_IconOption(12), new Fa_IconOption(0, new Fa_ISize(7))]), [])]),
});

export const sqrt3 = Math.sqrt(3);

export function renderHex(width, color, text) {
    const height = (~(~(width / sqrt3))) | 0;
    const halfWidth = (~(~(width / 2))) | 0;
    const halfHeight = (~(~(height / 2))) | 0;
    const children = ofArray([createElement("div", {
        style: {
            width: 0,
            borderBottom: (((halfHeight + "px ") + "solid") + " ") + color,
            borderLeft: (((halfWidth + "px ") + "solid") + " ") + "transparent",
            borderRight: (((halfWidth + "px ") + "solid") + " ") + "transparent",
        },
    }), createElement("div", {
        children: text,
        className: "centre-hex",
        style: {
            width: width,
            height: height,
            backgroundColor: color,
        },
    }), createElement("div", {
        style: {
            width: 0,
            borderTop: (((halfHeight + "px ") + "solid") + " ") + color,
            borderLeft: (((halfWidth + "px ") + "solid") + " ") + "transparent",
            borderRight: (((halfWidth + "px ") + "solid") + " ") + "transparent",
        },
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function renderRankRating(game, className) {
    return createElement("div", {
        className: join(" ", ["is-flex", className]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "ranks",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["ttg-rank", "tooltip"]),
                ["data-tooltip"]: "Try These Games Rank",
                style: {
                    backgroundImage: "url(\u0027/android-chrome-192x192.png\u0027)",
                },
                children: game.Rank,
            }), createElement("div", {
                className: join(" ", ["bgg-rank", "tooltip"]),
                ["data-tooltip"]: "BoardGameGeek Rank",
                style: {
                    backgroundImage: "url(\u0027https://cf.geekdo-static.com/images/logos/bgg-primary-logo-reverse.svg\u0027)",
                },
                children: game.BggRank,
            })]),
        }), createElement("div", {
            className: "ratings",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["tooltip", "ttg-rating"]),
                ["data-tooltip"]: "Try These Games Rating",
                children: Interop_reactApi.Children.toArray([renderHex(34, "#EF8F0E", Float_toFixed(1, game.TtgRating))]),
            }), createElement("div", {
                className: join(" ", ["tooltip", "geek-rating"]),
                ["data-tooltip"]: "Geek Rating",
                children: Interop_reactApi.Children.toArray([renderHex(34, "#FF5100", Float_toFixed(1, game.GeekRating))]),
            })]),
        })]),
    });
}

export function renderGame(game) {
    let children, children_2, children_4, value_46, children_6, value_50;
    const thumbnailUrl = defaultArg(game.Thumbnail, "");
    return createElement("div", {
        className: "ranking game",
        children: Interop_reactApi.Children.toArray([renderRankRating(game, "is-hidden-mobile"), (children = ofArray([createElement("div", {
            className: "image-background",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "image-background-image",
                style: {
                    backgroundImage: ("url(\u0027" + thumbnailUrl) + "\u0027)",
                },
            })]),
        }), createElement("div", {
            className: "image-container",
            children: Interop_reactApi.Children.toArray([createElement("img", {
                className: "game-image",
                src: thumbnailUrl,
            })]),
        })]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("div", {
            className: "game-information",
            children: Interop_reactApi.Children.toArray([(children_2 = ofArray([createElement("span", {
                className: "game-name",
                children: game.Name,
            }), createElement("span", {
                className: "year-published",
                children: toText(interpolate(" (%i%P()) ", [game.YearPublished])),
            }), viewLink(new Page(0, game.Id), [["className", "replace-game tooltip"], ["data-tooltip", "See recommendations"], ["children", Interop_reactApi.Children.toArray([Fa_i(singleton(new Fa_IconOption(11, "fas fa-thumbs-up")), [])])]]), createElement("a", {
                href: toText(interpolate("https://boardgamegeek.com/boardgame/%i%P()", [game.Id])),
                target: "_blank",
                rel: "noopener",
                className: "tooltip",
                ["data-tooltip"]: "BoardGameGeek",
                children: Interop_reactApi.Children.toArray([Fa_i(singleton(new Fa_IconOption(11, "far fa-external-link")), [])]),
            })]), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            })), createElement("div", {
                className: "game-breakdown",
                children: Interop_reactApi.Children.toArray([renderRankRating(game, "is-hidden-tablet"), createElement("div", {
                    className: join(" ", ["game-stats", "is-hidden-mobile"]),
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        className: "game-stat",
                        children: Interop_reactApi.Children.toArray([(children_4 = singleton(Fa_i(ofArray([new Fa_IconOption(11, "fas fa-users"), new Fa_IconOption(0, new Fa_ISize(3))]), [])), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                        })), (value_46 = toText(interpolate("%s%P() Players", [getRangeString(game.MinPlayers, game.MaxPlayers)])), createElement("div", {
                            children: [value_46],
                        }))]),
                    }), createElement("div", {
                        className: "game-stat",
                        children: Interop_reactApi.Children.toArray([(children_6 = singleton(Fa_i(ofArray([new Fa_IconOption(11, "far fa-stopwatch"), new Fa_IconOption(0, new Fa_ISize(3))]), [])), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                        })), (value_50 = toText(interpolate("%s%P() Min", [getRangeString(game.MinPlayingTime, game.MaxPlayingTime)])), createElement("div", {
                            children: [value_50],
                        }))]),
                    })]),
                })]),
            })]),
        }), timeTrend(new TimeTrendData(game.RatingTrendCoefficients, game.RatingTrendStartDate, game.RatingTrendEndDate))]),
    });
}

export function render(model, dispatch) {
    const children_2 = toList(delay(() => {
        let props_3;
        return append_1(singleton_1((props_3 = ofArray([["className", "about-bar"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            href: "mailto:admin@trythesegames.com",
            className: "contact-link",
            children: "Contact",
        }), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-small"], ["className", "is-info"], ["onClick", (_arg1) => {
            dispatch(new Msg(6));
        }], ["children", "Glossary"]])))), viewLinkButton(new Page(0, void 0), [["className", "is-small"], ["className", "is-primary"], ["children", "Recommendations"]])])]]), createElement("div", createObj(Helpers_combineClasses("container", props_3))))), delay(() => {
            let elms;
            return append_1(singleton_1((elms = singleton(createElement("h1", createObj(Helpers_combineClasses("title is-1", ofArray([["className", "is-1"], ["children", "Try these games rankings"], ["className", "ranking-heading"]]))))), createElement("div", {
                className: "container",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))), delay(() => {
                let matchValue, elms_1, props_17, props_13, props_15, matchValue_3, matchValue_2;
                return append_1((matchValue = model.RankedBoardGames, (matchValue.tag === 2) ? singleton_1((elms_1 = singleton((props_17 = ofArray([["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", "is-three-fifths-fullhd"], ["className", "is-two-thirds-desktop"], ["className", "is-full-tablet"], ["className", "is-full-mobile"], ["className", "games-column"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    const matchValue_1 = model.RankedBoardGames;
                    return (matchValue_1.tag === 1) ? singleton_1(loadingSpinner) : ((matchValue_1.tag === 3) ? (isEmpty(matchValue_1.fields[0]) ? singleton_1(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-info"], ["children", "No matching games"]]))))) : (matchValue_1.fields[1] ? append_1(map((game_2) => renderGame(game_2), matchValue_1.fields[0]), delay(() => singleton_1(createElement("div", {
                        className: "has-text-centered",
                        children: Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg2) => {
                            dispatch(new Msg(0, new AsyncOperation$1(0)));
                        }], ["children", "Load More"]]))))]),
                    })))) : map((game_1) => renderGame(game_1), matchValue_1.fields[0]))) : ((matchValue_1.tag === 2) ? append_1(map((game) => renderGame(game), matchValue_1.fields[0]), delay(() => singleton_1(loadingSpinner))) : singleton_1(null)));
                }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_13)))), (props_15 = ofArray([["className", "filter-column"], ["className", "is-two-fifths-fullhd"], ["className", "is-one-third-desktop"], ["children", Interop_reactApi.Children.toArray([rankingFilter({
                    Categories: (matchValue_3 = model.Categories, (matchValue_3.tag === 2) ? matchValue_3.fields[0] : empty()),
                    ClearFilter: () => {
                        dispatch(new Msg(3, emptyFilter));
                    },
                    Filter: model.Filter,
                    Mechanics: (matchValue_2 = model.Mechanics, (matchValue_2.tag === 2) ? matchValue_2.fields[0] : empty()),
                    MobileFilterOpen: model.MobileFilterOpen,
                    OnFilter: () => {
                        dispatch(new Msg(4));
                    },
                    OnFilterUpdated: (arg) => {
                        dispatch(new Msg(3, arg));
                    },
                    OnMobileFilterToggled: () => {
                        dispatch(new Msg(5));
                    },
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_15))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_17))))), createElement("div", {
                    className: "container",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))) : ((matchValue.tag === 3) ? singleton_1((elms_1 = singleton((props_17 = ofArray([["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", "is-three-fifths-fullhd"], ["className", "is-two-thirds-desktop"], ["className", "is-full-tablet"], ["className", "is-full-mobile"], ["className", "games-column"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    const matchValue_1 = model.RankedBoardGames;
                    return (matchValue_1.tag === 1) ? singleton_1(loadingSpinner) : ((matchValue_1.tag === 3) ? (isEmpty(matchValue_1.fields[0]) ? singleton_1(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-info"], ["children", "No matching games"]]))))) : (matchValue_1.fields[1] ? append_1(map((game_2) => renderGame(game_2), matchValue_1.fields[0]), delay(() => singleton_1(createElement("div", {
                        className: "has-text-centered",
                        children: Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg2) => {
                            dispatch(new Msg(0, new AsyncOperation$1(0)));
                        }], ["children", "Load More"]]))))]),
                    })))) : map((game_1) => renderGame(game_1), matchValue_1.fields[0]))) : ((matchValue_1.tag === 2) ? append_1(map((game) => renderGame(game), matchValue_1.fields[0]), delay(() => singleton_1(loadingSpinner))) : singleton_1(null)));
                }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_13)))), (props_15 = ofArray([["className", "filter-column"], ["className", "is-two-fifths-fullhd"], ["className", "is-one-third-desktop"], ["children", Interop_reactApi.Children.toArray([rankingFilter({
                    Categories: (matchValue_3 = model.Categories, (matchValue_3.tag === 2) ? matchValue_3.fields[0] : empty()),
                    ClearFilter: () => {
                        dispatch(new Msg(3, emptyFilter));
                    },
                    Filter: model.Filter,
                    Mechanics: (matchValue_2 = model.Mechanics, (matchValue_2.tag === 2) ? matchValue_2.fields[0] : empty()),
                    MobileFilterOpen: model.MobileFilterOpen,
                    OnFilter: () => {
                        dispatch(new Msg(4));
                    },
                    OnFilterUpdated: (arg) => {
                        dispatch(new Msg(3, arg));
                    },
                    OnMobileFilterToggled: () => {
                        dispatch(new Msg(5));
                    },
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_15))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_17))))), createElement("div", {
                    className: "container",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))) : ((matchValue.tag === 0) ? singleton_1(null) : singleton_1((elms_1 = singleton((props_17 = ofArray([["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", "is-three-fifths-fullhd"], ["className", "is-two-thirds-desktop"], ["className", "is-full-tablet"], ["className", "is-full-mobile"], ["className", "games-column"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    const matchValue_1 = model.RankedBoardGames;
                    return (matchValue_1.tag === 1) ? singleton_1(loadingSpinner) : ((matchValue_1.tag === 3) ? (isEmpty(matchValue_1.fields[0]) ? singleton_1(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-info"], ["children", "No matching games"]]))))) : (matchValue_1.fields[1] ? append_1(map((game_2) => renderGame(game_2), matchValue_1.fields[0]), delay(() => singleton_1(createElement("div", {
                        className: "has-text-centered",
                        children: Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg2) => {
                            dispatch(new Msg(0, new AsyncOperation$1(0)));
                        }], ["children", "Load More"]]))))]),
                    })))) : map((game_1) => renderGame(game_1), matchValue_1.fields[0]))) : ((matchValue_1.tag === 2) ? append_1(map((game) => renderGame(game), matchValue_1.fields[0]), delay(() => singleton_1(loadingSpinner))) : singleton_1(null)));
                }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_13)))), (props_15 = ofArray([["className", "filter-column"], ["className", "is-two-fifths-fullhd"], ["className", "is-one-third-desktop"], ["children", Interop_reactApi.Children.toArray([rankingFilter({
                    Categories: (matchValue_3 = model.Categories, (matchValue_3.tag === 2) ? matchValue_3.fields[0] : empty()),
                    ClearFilter: () => {
                        dispatch(new Msg(3, emptyFilter));
                    },
                    Filter: model.Filter,
                    Mechanics: (matchValue_2 = model.Mechanics, (matchValue_2.tag === 2) ? matchValue_2.fields[0] : empty()),
                    MobileFilterOpen: model.MobileFilterOpen,
                    OnFilter: () => {
                        dispatch(new Msg(4));
                    },
                    OnFilterUpdated: (arg) => {
                        dispatch(new Msg(3, arg));
                    },
                    OnMobileFilterToggled: () => {
                        dispatch(new Msg(5));
                    },
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_15))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_17))))), createElement("div", {
                    className: "container",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                })))))), delay(() => singleton_1(rankingsGlossaryModal(model.GlossaryModalActive, (_arg3) => {
                    dispatch(new Msg(6));
                }))));
            }));
        }));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}

