import { FSharpRef, toString, Union } from "./.fable/fable-library.3.1.12/Types.js";
import { bool_type, lambda_type, unit_type, union_type } from "./.fable/fable-library.3.1.12/Reflection.js";
import { map, defaultArg } from "./.fable/fable-library.3.1.12/Option.js";
import { tryParse } from "./.fable/fable-library.3.1.12/Int32.js";
import { tryParse as tryParse_1 } from "./.fable/fable-library.3.1.12/Double.js";

export class AsyncOperation$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Started", "Finished"];
    }
}

export function AsyncOperation$1$reflection(gen0) {
    return union_type("Client.Helpers.AsyncOperation`1", [gen0], AsyncOperation$1, () => [[], [["Item", gen0]]]);
}

export class Deferred$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["HasNotStarted", "InProgress", "Finished"];
    }
}

export function Deferred$1$reflection(gen0) {
    return union_type("Client.Helpers.Deferred`1", [gen0], Deferred$1, () => [[], [], [["Item", gen0]]]);
}

export class CancellableDeferred$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["HasNotStarted", "InProgress", "Finished"];
    }
}

export function CancellableDeferred$1$reflection(gen0) {
    return union_type("Client.Helpers.CancellableDeferred`1", [gen0], CancellableDeferred$1, () => [[], [["Item", lambda_type(unit_type, unit_type)]], [["Item", gen0]]]);
}

export class CancellablePagedDeferred$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["HasNotStarted", "GettingFirstPage", "GettingNextPage", "Finished"];
    }
}

export function CancellablePagedDeferred$1$reflection(gen0) {
    return union_type("Client.Helpers.CancellablePagedDeferred`1", [gen0], CancellablePagedDeferred$1, () => [[], [["Item", lambda_type(unit_type, unit_type)]], [["Item1", gen0], ["Item2", lambda_type(unit_type, unit_type)]], [["Item1", gen0], ["Item2", bool_type]]]);
}

export function toQueryString(o, excludeKeys) {
    const queryComponents = Object.entries(o)
    .filter(([key,]) => !excludeKeys.includes(key))
    .filter(([,value]) => value !== null && value !== undefined)
    .map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value));
    return queryComponents.length ? '?' + queryComponents.join('&') : '';;
}

export function Option_toString(o) {
    return defaultArg(map((value) => toString(value), o), "");
}

export function intTryParse(str) {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse(str, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function floatTryParse(str) {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse_1(str, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function Float_toFixed(dps, x) {
    return x.toFixed(dps);
}

