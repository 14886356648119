import { Union, Record } from "../TryTheseGames.Website.Client/.fable/fable-library.3.1.12/Types.js";
import { list_type, union_type, class_type, array_type, float64_type, string_type, record_type, option_type, bool_type, int32_type } from "../TryTheseGames.Website.Client/.fable/fable-library.3.1.12/Reflection.js";

export class RecommendationQuery extends Record {
    constructor(GameId, HideLinkedItems, HideMatchingDesigners, Players, HasMechanic, HasCategory, MinPlayingTime, MaxPlayingTime, PublishedBeforeYear, PublishedAfterYear, Skip) {
        super();
        this.GameId = (GameId | 0);
        this.HideLinkedItems = HideLinkedItems;
        this.HideMatchingDesigners = HideMatchingDesigners;
        this.Players = Players;
        this.HasMechanic = HasMechanic;
        this.HasCategory = HasCategory;
        this.MinPlayingTime = MinPlayingTime;
        this.MaxPlayingTime = MaxPlayingTime;
        this.PublishedBeforeYear = PublishedBeforeYear;
        this.PublishedAfterYear = PublishedAfterYear;
        this.Skip = (Skip | 0);
    }
}

export function RecommendationQuery$reflection() {
    return record_type("Shared.RecommendationQuery", [], RecommendationQuery, () => [["GameId", int32_type], ["HideLinkedItems", bool_type], ["HideMatchingDesigners", bool_type], ["Players", option_type(int32_type)], ["HasMechanic", option_type(int32_type)], ["HasCategory", option_type(int32_type)], ["MinPlayingTime", option_type(int32_type)], ["MaxPlayingTime", option_type(int32_type)], ["PublishedBeforeYear", option_type(int32_type)], ["PublishedAfterYear", option_type(int32_type)], ["Skip", int32_type]]);
}

export class Mechanic extends Record {
    constructor(Id, Name) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
    }
}

export function Mechanic$reflection() {
    return record_type("Shared.Mechanic", [], Mechanic, () => [["Id", int32_type], ["Name", string_type]]);
}

export class Category extends Record {
    constructor(Id, Name) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
    }
}

export function Category$reflection() {
    return record_type("Shared.Category", [], Category, () => [["Id", int32_type], ["Name", string_type]]);
}

export class BoardGame extends Record {
    constructor(Id, Name, Thumbnail, YearPublished, MinPlayers, MaxPlayers, MinPlayingTime, MaxPlayingTime, AlternativeName, RatingTrendCoefficients, RatingTrendStartDate, RatingTrendEndDate, MechanicIds, CategoryIds) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Thumbnail = Thumbnail;
        this.YearPublished = (YearPublished | 0);
        this.MinPlayers = (MinPlayers | 0);
        this.MaxPlayers = (MaxPlayers | 0);
        this.MinPlayingTime = (MinPlayingTime | 0);
        this.MaxPlayingTime = (MaxPlayingTime | 0);
        this.AlternativeName = AlternativeName;
        this.RatingTrendCoefficients = RatingTrendCoefficients;
        this.RatingTrendStartDate = RatingTrendStartDate;
        this.RatingTrendEndDate = RatingTrendEndDate;
        this.MechanicIds = MechanicIds;
        this.CategoryIds = CategoryIds;
    }
}

export function BoardGame$reflection() {
    return record_type("Shared.BoardGame", [], BoardGame, () => [["Id", int32_type], ["Name", string_type], ["Thumbnail", option_type(string_type)], ["YearPublished", int32_type], ["MinPlayers", int32_type], ["MaxPlayers", int32_type], ["MinPlayingTime", int32_type], ["MaxPlayingTime", int32_type], ["AlternativeName", option_type(string_type)], ["RatingTrendCoefficients", option_type(array_type(float64_type))], ["RatingTrendStartDate", option_type(class_type("System.DateTime"))], ["RatingTrendEndDate", option_type(class_type("System.DateTime"))], ["MechanicIds", array_type(int32_type)], ["CategoryIds", array_type(int32_type)]]);
}

export class TimeTrendData extends Record {
    constructor(RatingTrendCoefficients, RatingTrendStartDate, RatingTrendEndDate) {
        super();
        this.RatingTrendCoefficients = RatingTrendCoefficients;
        this.RatingTrendStartDate = RatingTrendStartDate;
        this.RatingTrendEndDate = RatingTrendEndDate;
    }
}

export function TimeTrendData$reflection() {
    return record_type("Shared.TimeTrendData", [], TimeTrendData, () => [["RatingTrendCoefficients", option_type(array_type(float64_type))], ["RatingTrendStartDate", option_type(class_type("System.DateTime"))], ["RatingTrendEndDate", option_type(class_type("System.DateTime"))]]);
}

export class BoardGameRecommendation extends Record {
    constructor(Id, Name, Thumbnail, YearPublished, GameScore, UserScore, LikeScore, Strength, Surety, MinPlayers, MaxPlayers, MinPlayingTime, MaxPlayingTime, MechanicIds, CategoryIds) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Thumbnail = Thumbnail;
        this.YearPublished = (YearPublished | 0);
        this.GameScore = GameScore;
        this.UserScore = UserScore;
        this.LikeScore = LikeScore;
        this.Strength = (Strength | 0);
        this.Surety = (Surety | 0);
        this.MinPlayers = (MinPlayers | 0);
        this.MaxPlayers = (MaxPlayers | 0);
        this.MinPlayingTime = (MinPlayingTime | 0);
        this.MaxPlayingTime = (MaxPlayingTime | 0);
        this.MechanicIds = MechanicIds;
        this.CategoryIds = CategoryIds;
    }
}

export function BoardGameRecommendation$reflection() {
    return record_type("Shared.BoardGameRecommendation", [], BoardGameRecommendation, () => [["Id", int32_type], ["Name", string_type], ["Thumbnail", option_type(string_type)], ["YearPublished", int32_type], ["GameScore", float64_type], ["UserScore", float64_type], ["LikeScore", float64_type], ["Strength", int32_type], ["Surety", int32_type], ["MinPlayers", int32_type], ["MaxPlayers", int32_type], ["MinPlayingTime", int32_type], ["MaxPlayingTime", int32_type], ["MechanicIds", array_type(int32_type)], ["CategoryIds", array_type(int32_type)]]);
}

export class RankedBoardGame extends Record {
    constructor(Id, Name, Thumbnail, YearPublished, MinPlayers, MaxPlayers, MinPlayingTime, MaxPlayingTime, Rank, BggRank, TtgRating, GeekRating, RatingTrendCoefficients, RatingTrendStartDate, RatingTrendEndDate) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Thumbnail = Thumbnail;
        this.YearPublished = (YearPublished | 0);
        this.MinPlayers = (MinPlayers | 0);
        this.MaxPlayers = (MaxPlayers | 0);
        this.MinPlayingTime = (MinPlayingTime | 0);
        this.MaxPlayingTime = (MaxPlayingTime | 0);
        this.Rank = (Rank | 0);
        this.BggRank = (BggRank | 0);
        this.TtgRating = TtgRating;
        this.GeekRating = GeekRating;
        this.RatingTrendCoefficients = RatingTrendCoefficients;
        this.RatingTrendStartDate = RatingTrendStartDate;
        this.RatingTrendEndDate = RatingTrendEndDate;
    }
}

export function RankedBoardGame$reflection() {
    return record_type("Shared.RankedBoardGame", [], RankedBoardGame, () => [["Id", int32_type], ["Name", string_type], ["Thumbnail", option_type(string_type)], ["YearPublished", int32_type], ["MinPlayers", int32_type], ["MaxPlayers", int32_type], ["MinPlayingTime", int32_type], ["MaxPlayingTime", int32_type], ["Rank", int32_type], ["BggRank", int32_type], ["TtgRating", float64_type], ["GeekRating", float64_type], ["RatingTrendCoefficients", option_type(array_type(float64_type))], ["RatingTrendStartDate", option_type(class_type("System.DateTime"))], ["RatingTrendEndDate", option_type(class_type("System.DateTime"))]]);
}

export class RankingTimePeriod extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["All", "OneYear", "TwoYears", "FourYears"];
    }
}

export function RankingTimePeriod$reflection() {
    return union_type("Shared.RankingTimePeriod", [], RankingTimePeriod, () => [[], [], [], []]);
}

export class NewGameAdjustment extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NoAdjustment", "Base", "Mild", "Moderate", "Severe"];
    }
}

export function NewGameAdjustment$reflection() {
    return union_type("Shared.NewGameAdjustment", [], NewGameAdjustment, () => [[], [], [], [], []]);
}

export class RankingQuery extends Record {
    constructor(TimePeriod, NewGameAdjustment, PseudoRaters, PlayerCount, MinPlayTime, MaxPlayTime, MinPublishYear, MaxPublishYear, HasMechanic, HasCategory, Skip) {
        super();
        this.TimePeriod = TimePeriod;
        this.NewGameAdjustment = NewGameAdjustment;
        this.PseudoRaters = (PseudoRaters | 0);
        this.PlayerCount = PlayerCount;
        this.MinPlayTime = MinPlayTime;
        this.MaxPlayTime = MaxPlayTime;
        this.MinPublishYear = MinPublishYear;
        this.MaxPublishYear = MaxPublishYear;
        this.HasMechanic = HasMechanic;
        this.HasCategory = HasCategory;
        this.Skip = (Skip | 0);
    }
}

export function RankingQuery$reflection() {
    return record_type("Shared.RankingQuery", [], RankingQuery, () => [["TimePeriod", RankingTimePeriod$reflection()], ["NewGameAdjustment", NewGameAdjustment$reflection()], ["PseudoRaters", int32_type], ["PlayerCount", option_type(int32_type)], ["MinPlayTime", option_type(int32_type)], ["MaxPlayTime", option_type(int32_type)], ["MinPublishYear", option_type(int32_type)], ["MaxPublishYear", option_type(int32_type)], ["HasMechanic", option_type(int32_type)], ["HasCategory", option_type(int32_type)], ["Skip", int32_type]]);
}

export class Page$1 extends Record {
    constructor(PageSize, TotalCount, Items) {
        super();
        this.PageSize = (PageSize | 0);
        this.TotalCount = (TotalCount | 0);
        this.Items = Items;
    }
}

export function Page$1$reflection(gen0) {
    return record_type("Shared.Page`1", [gen0], Page$1, () => [["PageSize", int32_type], ["TotalCount", int32_type], ["Items", list_type(gen0)]]);
}

