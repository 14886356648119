import { FSharpRef, Union, Record } from "../.fable/fable-library.3.1.12/Types.js";
import { union_type, string_type, array_type, list_type, record_type, option_type, int32_type, bool_type } from "../.fable/fable-library.3.1.12/Reflection.js";
import { Category$reflection, Mechanic$reflection, RecommendationQuery } from "../../TryTheseGames.Website.Shared/Shared.fs.js";
import { singleton as singleton_1, cons, ofArray, empty } from "../.fable/fable-library.3.1.12/List.js";
import { tryParse } from "../.fable/fable-library.3.1.12/Int32.js";
import { Cmd_none } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.49.0/Interop.fs.js";
import { createElement } from "react";
import { Fa_IconOption, Fa_i } from "../.fable/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { filter, map, singleton, delay, toList } from "../.fable/fable-library.3.1.12/Seq.js";
import { numberHash, int32ToString, createObj } from "../.fable/fable-library.3.1.12/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { Option_toString } from "../Helpers.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import { contains } from "../.fable/fable-library.3.1.12/Array.js";
import { join } from "../.fable/fable-library.3.1.12/String.js";

export class Filter extends Record {
    constructor(HideLinkedItems, HideMatchingDesigners, Players, HasMechanic, HasCategory, MinPlayingTime, MaxPlayingTime, PublishedBeforeYear, PublishedAfterYear) {
        super();
        this.HideLinkedItems = HideLinkedItems;
        this.HideMatchingDesigners = HideMatchingDesigners;
        this.Players = Players;
        this.HasMechanic = HasMechanic;
        this.HasCategory = HasCategory;
        this.MinPlayingTime = MinPlayingTime;
        this.MaxPlayingTime = MaxPlayingTime;
        this.PublishedBeforeYear = PublishedBeforeYear;
        this.PublishedAfterYear = PublishedAfterYear;
    }
}

export function Filter$reflection() {
    return record_type("Client.Recommendations.RecommendationFilter.Filter", [], Filter, () => [["HideLinkedItems", bool_type], ["HideMatchingDesigners", bool_type], ["Players", option_type(int32_type)], ["HasMechanic", option_type(int32_type)], ["HasCategory", option_type(int32_type)], ["MinPlayingTime", option_type(int32_type)], ["MaxPlayingTime", option_type(int32_type)], ["PublishedBeforeYear", option_type(int32_type)], ["PublishedAfterYear", option_type(int32_type)]]);
}

export function Filter__ToRecommendationsFilter(this$, gameId, skip) {
    return new RecommendationQuery(gameId, this$.HideLinkedItems, this$.HideMatchingDesigners, this$.Players, this$.HasMechanic, this$.HasCategory, this$.MinPlayingTime, this$.MaxPlayingTime, this$.PublishedBeforeYear, this$.PublishedAfterYear, skip);
}

export class Model extends Record {
    constructor(MobileFilterOpen, Mechanics, Categories, SelectedGameMechanicIds, SelectedGameCategoryIds, Filter) {
        super();
        this.MobileFilterOpen = MobileFilterOpen;
        this.Mechanics = Mechanics;
        this.Categories = Categories;
        this.SelectedGameMechanicIds = SelectedGameMechanicIds;
        this.SelectedGameCategoryIds = SelectedGameCategoryIds;
        this.Filter = Filter;
    }
}

export function Model$reflection() {
    return record_type("Client.Recommendations.RecommendationFilter.Model", [], Model, () => [["MobileFilterOpen", bool_type], ["Mechanics", list_type(Mechanic$reflection())], ["Categories", list_type(Category$reflection())], ["SelectedGameMechanicIds", array_type(int32_type)], ["SelectedGameCategoryIds", array_type(int32_type)], ["Filter", Filter$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ToggleMobileFilter", "SetHideLinkedItems", "SetHideMatchingDesigners", "SetPlayers", "SetMinPlayingTime", "SetMaxPlayingTime", "SetPublishedAfterYear", "SetPublishedBeforeYear", "SetHasMechanic", "SetHasCategory", "SetMechanics", "SetCategories", "SetSelectedGameProperties", "ClearFilters", "FilterResults"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Recommendations.RecommendationFilter.Msg", [], Msg, () => [[], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", list_type(Mechanic$reflection())]], [["Item", list_type(Category$reflection())]], [["mechanicIds", array_type(int32_type)], ["categoryIds", array_type(int32_type)]], [], []]);
}

export const emptyFilter = new Filter(false, false, void 0, void 0, void 0, void 0, void 0, void 0, void 0);

export function init() {
    return new Model(false, empty(), empty(), new Int32Array([]), new Int32Array([]), emptyFilter);
}

export function intTryParse(str) {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse(str, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function update(msg, currentModel) {
    let inputRecord, inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8;
    switch (msg.tag) {
        case 1: {
            return [new Model(currentModel.MobileFilterOpen, currentModel.Mechanics, currentModel.Categories, currentModel.SelectedGameMechanicIds, currentModel.SelectedGameCategoryIds, (inputRecord = currentModel.Filter, new Filter(msg.fields[0], inputRecord.HideMatchingDesigners, inputRecord.Players, inputRecord.HasMechanic, inputRecord.HasCategory, inputRecord.MinPlayingTime, inputRecord.MaxPlayingTime, inputRecord.PublishedBeforeYear, inputRecord.PublishedAfterYear))), Cmd_none()];
        }
        case 2: {
            return [new Model(currentModel.MobileFilterOpen, currentModel.Mechanics, currentModel.Categories, currentModel.SelectedGameMechanicIds, currentModel.SelectedGameCategoryIds, (inputRecord_1 = currentModel.Filter, new Filter(inputRecord_1.HideLinkedItems, msg.fields[0], inputRecord_1.Players, inputRecord_1.HasMechanic, inputRecord_1.HasCategory, inputRecord_1.MinPlayingTime, inputRecord_1.MaxPlayingTime, inputRecord_1.PublishedBeforeYear, inputRecord_1.PublishedAfterYear))), Cmd_none()];
        }
        case 3: {
            return [new Model(currentModel.MobileFilterOpen, currentModel.Mechanics, currentModel.Categories, currentModel.SelectedGameMechanicIds, currentModel.SelectedGameCategoryIds, (inputRecord_2 = currentModel.Filter, new Filter(inputRecord_2.HideLinkedItems, inputRecord_2.HideMatchingDesigners, intTryParse(msg.fields[0]), inputRecord_2.HasMechanic, inputRecord_2.HasCategory, inputRecord_2.MinPlayingTime, inputRecord_2.MaxPlayingTime, inputRecord_2.PublishedBeforeYear, inputRecord_2.PublishedAfterYear))), Cmd_none()];
        }
        case 4: {
            return [new Model(currentModel.MobileFilterOpen, currentModel.Mechanics, currentModel.Categories, currentModel.SelectedGameMechanicIds, currentModel.SelectedGameCategoryIds, (inputRecord_3 = currentModel.Filter, new Filter(inputRecord_3.HideLinkedItems, inputRecord_3.HideMatchingDesigners, inputRecord_3.Players, inputRecord_3.HasMechanic, inputRecord_3.HasCategory, intTryParse(msg.fields[0]), inputRecord_3.MaxPlayingTime, inputRecord_3.PublishedBeforeYear, inputRecord_3.PublishedAfterYear))), Cmd_none()];
        }
        case 5: {
            return [new Model(currentModel.MobileFilterOpen, currentModel.Mechanics, currentModel.Categories, currentModel.SelectedGameMechanicIds, currentModel.SelectedGameCategoryIds, (inputRecord_4 = currentModel.Filter, new Filter(inputRecord_4.HideLinkedItems, inputRecord_4.HideMatchingDesigners, inputRecord_4.Players, inputRecord_4.HasMechanic, inputRecord_4.HasCategory, inputRecord_4.MinPlayingTime, intTryParse(msg.fields[0]), inputRecord_4.PublishedBeforeYear, inputRecord_4.PublishedAfterYear))), Cmd_none()];
        }
        case 6: {
            return [new Model(currentModel.MobileFilterOpen, currentModel.Mechanics, currentModel.Categories, currentModel.SelectedGameMechanicIds, currentModel.SelectedGameCategoryIds, (inputRecord_5 = currentModel.Filter, new Filter(inputRecord_5.HideLinkedItems, inputRecord_5.HideMatchingDesigners, inputRecord_5.Players, inputRecord_5.HasMechanic, inputRecord_5.HasCategory, inputRecord_5.MinPlayingTime, inputRecord_5.MaxPlayingTime, inputRecord_5.PublishedBeforeYear, intTryParse(msg.fields[0])))), Cmd_none()];
        }
        case 7: {
            return [new Model(currentModel.MobileFilterOpen, currentModel.Mechanics, currentModel.Categories, currentModel.SelectedGameMechanicIds, currentModel.SelectedGameCategoryIds, (inputRecord_6 = currentModel.Filter, new Filter(inputRecord_6.HideLinkedItems, inputRecord_6.HideMatchingDesigners, inputRecord_6.Players, inputRecord_6.HasMechanic, inputRecord_6.HasCategory, inputRecord_6.MinPlayingTime, inputRecord_6.MaxPlayingTime, intTryParse(msg.fields[0]), inputRecord_6.PublishedAfterYear))), Cmd_none()];
        }
        case 8: {
            return [new Model(currentModel.MobileFilterOpen, currentModel.Mechanics, currentModel.Categories, currentModel.SelectedGameMechanicIds, currentModel.SelectedGameCategoryIds, (inputRecord_7 = currentModel.Filter, new Filter(inputRecord_7.HideLinkedItems, inputRecord_7.HideMatchingDesigners, inputRecord_7.Players, intTryParse(msg.fields[0]), inputRecord_7.HasCategory, inputRecord_7.MinPlayingTime, inputRecord_7.MaxPlayingTime, inputRecord_7.PublishedBeforeYear, inputRecord_7.PublishedAfterYear))), Cmd_none()];
        }
        case 9: {
            return [new Model(currentModel.MobileFilterOpen, currentModel.Mechanics, currentModel.Categories, currentModel.SelectedGameMechanicIds, currentModel.SelectedGameCategoryIds, (inputRecord_8 = currentModel.Filter, new Filter(inputRecord_8.HideLinkedItems, inputRecord_8.HideMatchingDesigners, inputRecord_8.Players, inputRecord_8.HasMechanic, intTryParse(msg.fields[0]), inputRecord_8.MinPlayingTime, inputRecord_8.MaxPlayingTime, inputRecord_8.PublishedBeforeYear, inputRecord_8.PublishedAfterYear))), Cmd_none()];
        }
        case 10: {
            return [new Model(currentModel.MobileFilterOpen, msg.fields[0], currentModel.Categories, currentModel.SelectedGameMechanicIds, currentModel.SelectedGameCategoryIds, currentModel.Filter), Cmd_none()];
        }
        case 11: {
            return [new Model(currentModel.MobileFilterOpen, currentModel.Mechanics, msg.fields[0], currentModel.SelectedGameMechanicIds, currentModel.SelectedGameCategoryIds, currentModel.Filter), Cmd_none()];
        }
        case 12: {
            return [new Model(currentModel.MobileFilterOpen, currentModel.Mechanics, currentModel.Categories, msg.fields[0], msg.fields[1], currentModel.Filter), Cmd_none()];
        }
        case 13: {
            return [new Model(currentModel.MobileFilterOpen, currentModel.Mechanics, currentModel.Categories, currentModel.SelectedGameMechanicIds, currentModel.SelectedGameCategoryIds, emptyFilter), Cmd_none()];
        }
        case 14: {
            return [currentModel, Cmd_none()];
        }
        default: {
            return [new Model(!currentModel.MobileFilterOpen, currentModel.Mechanics, currentModel.Categories, currentModel.SelectedGameMechanicIds, currentModel.SelectedGameCategoryIds, currentModel.Filter), Cmd_none()];
        }
    }
}

export function render(model, dispatch) {
    let children, props_14, props_12, children_2, props_20, props_18, children_4, props_26, props_24, children_6, props_33, props_31, children_8, props_39, props_37, children_12, props_54, children_10, patternInput, matchValue, children_16, props_68, children_14, patternInput_1, matchValue_1;
    const props_78 = ofArray([["className", model.MobileFilterOpen ? "filter-box open" : "filter-box"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        className: "mobile-filter-button",
        onClick: (_arg1) => {
            dispatch(new Msg(0));
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "mobile-filter-button-text",
            children: Interop_reactApi.Children.toArray([Fa_i(toList(delay(() => (model.MobileFilterOpen ? singleton(new Fa_IconOption(11, "fas fa-chevron-double-down")) : singleton(new Fa_IconOption(11, "fas fa-chevron-double-up"))))), []), createElement("span", {
                children: ["Filter"],
            }), Fa_i(toList(delay(() => (model.MobileFilterOpen ? singleton(new Fa_IconOption(11, "fas fa-chevron-double-down")) : singleton(new Fa_IconOption(11, "fas fa-chevron-double-up"))))), [])]),
        })]),
    }), createElement("div", {
        className: "filter-container",
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "filter-options filter-subcontainer",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "checkbox-option",
                children: Interop_reactApi.Children.toArray([createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("is-checkradio", ofArray([["id", "hide-linked-items"], ["className", "is-info"], ["onChange", (ev) => {
                    dispatch(new Msg(1, ev.target.checked));
                }], ["checked", model.Filter.HideLinkedItems]]))))), createElement("label", {
                    htmlFor: "hide-linked-items",
                    children: "Hide Linked Items",
                })]),
            }), createElement("div", {
                className: "checkbox-option",
                children: Interop_reactApi.Children.toArray([createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("is-checkradio", ofArray([["id", "hide-matching-designers"], ["className", "is-info"], ["onChange", (ev_1) => {
                    dispatch(new Msg(2, ev_1.target.checked));
                }], ["checked", model.Filter.HideMatchingDesigners]]))))), createElement("label", {
                    htmlFor: "hide-matching-designers",
                    children: "Hide Matching Designers",
                })]),
            }), (children = singleton_1((props_14 = ofArray([["htmlFor", "players"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                children: ["Number of players"],
            }), (props_12 = ofArray([["id", "players"], ["placeholder", "Any"], ["onChange", (ev_2) => {
                dispatch(new Msg(3, ev_2.target.value));
            }], ["value", Option_toString(model.Filter.Players)]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_12)))))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_14))))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), createElement("div", {
                className: "range-input",
                children: Interop_reactApi.Children.toArray([(children_2 = singleton_1((props_20 = ofArray([["htmlFor", "minPlayTime"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                    children: ["Min play time (mins)"],
                }), (props_18 = ofArray([["id", "minPlayTime"], ["placeholder", "No Min"], ["onChange", (ev_3) => {
                    dispatch(new Msg(4, ev_3.target.value));
                }], ["value", Option_toString(model.Filter.MinPlayingTime)]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_18)))))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_20))))), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                })), (children_4 = singleton_1((props_26 = ofArray([["htmlFor", "maxPlayTime"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                    children: "Max play time (mins)",
                }), (props_24 = ofArray([["id", "maxPlayTime"], ["placeholder", "No Max"], ["onChange", (ev_4) => {
                    dispatch(new Msg(5, ev_4.target.value));
                }], ["value", Option_toString(model.Filter.MaxPlayingTime)]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_24)))))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_26))))), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                }))]),
            }), createElement("div", {
                className: "range-input",
                children: Interop_reactApi.Children.toArray([(children_6 = singleton_1((props_33 = ofArray([["htmlFor", "publishedAfter"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                    children: ["Published after year"],
                }), (props_31 = ofArray([["id", "publishedAfter"], ["placeholder", "The beginning of time"], ["onChange", (ev_5) => {
                    dispatch(new Msg(6, ev_5.target.value));
                }], ["value", Option_toString(model.Filter.PublishedAfterYear)]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_31)))))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_33))))), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                })), (children_8 = singleton_1((props_39 = ofArray([["htmlFor", "publishedBefore"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                    children: ["Published before year"],
                }), (props_37 = ofArray([["id", "publishedBefore"], ["placeholder", "Today"], ["onChange", (ev_6) => {
                    dispatch(new Msg(7, ev_6.target.value));
                }], ["value", Option_toString(model.Filter.PublishedBeforeYear)]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_37)))))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_39))))), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                }))]),
            }), (children_12 = singleton_1((props_54 = ofArray([["htmlFor", "hasMechanic"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                children: ["Has mechanic"],
            }), (children_10 = singleton_1((patternInput = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["value", (matchValue = model.Filter.HasMechanic, (matchValue == null) ? "" : int32ToString(matchValue))], ["onChange", (e) => {
                dispatch(new Msg(8, Browser_Types_Event__Event_get_Value(e)));
            }], ["children", Interop_reactApi.Children.toArray([createElement("option", {
                value: "",
                children: "Any",
            }), createElement("optgroup", {
                label: "Liked game",
                children: Interop_reactApi.Children.toArray(Array.from(map((m_2) => createElement("option", {
                    value: m_2.Id,
                    children: m_2.Name,
                }), filter((m_1) => contains(m_1.Id, model.SelectedGameMechanicIds, {
                    Equals: (x, y) => (x === y),
                    GetHashCode: (x) => numberHash(x),
                }), model.Mechanics)))),
            }), createElement("optgroup", {
                label: "Other",
                children: Interop_reactApi.Children.toArray(Array.from(map((m_4) => createElement("option", {
                    value: m_4.Id,
                    children: m_4.Name,
                }), filter((m_3) => (!contains(m_3.Id, model.SelectedGameMechanicIds, {
                    Equals: (x_1, y_1) => (x_1 === y_1),
                    GetHashCode: (x_1) => numberHash(x_1),
                })), model.Mechanics)))),
            })])]])), createElement("div", {
                className: join(" ", cons("select", patternInput[0])),
                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
            }))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_10)),
            }))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_54))))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_12)),
            })), (children_16 = singleton_1((props_68 = ofArray([["htmlFor", "hasCategory"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                children: ["Has category"],
            }), (children_14 = singleton_1((patternInput_1 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["value", (matchValue_1 = model.Filter.HasCategory, (matchValue_1 == null) ? "" : int32ToString(matchValue_1))], ["onChange", (e_1) => {
                dispatch(new Msg(9, Browser_Types_Event__Event_get_Value(e_1)));
            }], ["children", Interop_reactApi.Children.toArray([createElement("option", {
                value: "",
                children: "Any",
            }), createElement("optgroup", {
                label: "Liked game",
                children: Interop_reactApi.Children.toArray(Array.from(map((c_3) => createElement("option", {
                    value: c_3.Id,
                    children: c_3.Name,
                }), filter((c_2) => contains(c_2.Id, model.SelectedGameCategoryIds, {
                    Equals: (x_2, y_2) => (x_2 === y_2),
                    GetHashCode: (x_2) => numberHash(x_2),
                }), model.Categories)))),
            }), createElement("optgroup", {
                label: "Other",
                children: Interop_reactApi.Children.toArray(Array.from(map((c_5) => createElement("option", {
                    value: c_5.Id,
                    children: c_5.Name,
                }), filter((c_4) => (!contains(c_4.Id, model.SelectedGameCategoryIds, {
                    Equals: (x_3, y_3) => (x_3 === y_3),
                    GetHashCode: (x_3) => numberHash(x_3),
                })), model.Categories)))),
            })])]])), createElement("div", {
                className: join(" ", cons("select", patternInput_1[0])),
                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
            }))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_14)),
            }))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_68))))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_16)),
            }))]),
        }), createElement("div", {
            className: "filter-subcontainer",
            children: Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "clear-filter-button"], ["className", "is-fullwidth"], ["onClick", (_arg2) => {
                dispatch(new Msg(13));
            }], ["children", "Clear filters"]])))), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "filter-button"], ["className", "is-info"], ["className", "is-fullwidth"], ["onClick", (_arg3) => {
                dispatch(new Msg(14));
            }], ["children", "Filter"]]))))]),
        })]),
    })])]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_78)));
}

