import { empty, singleton, append, delay, toList } from "./.fable/fable-library.3.1.12/Seq.js";
import { Interop_reactApi } from "./.fable/Feliz.1.49.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "./.fable/fable-library.3.1.12/Util.js";
import { Helpers_combineClasses } from "./.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { ofArray, singleton as singleton_1 } from "./.fable/fable-library.3.1.12/List.js";

export function recommendationsGlossaryModal(isActive, close) {
    const props_24 = toList(delay(() => append(isActive ? singleton(["className", "is-active"]) : empty(), delay(() => {
        let elms_1, elms, props_21;
        return singleton(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", close])))), (elms_1 = ofArray([(elms = ofArray([createElement("p", {
            className: "modal-card-title",
            children: "Glossary",
        }), createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", close]))))]), createElement("header", {
            className: "modal-card-head",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_21 = ofArray([["className", "more-info-body"], ["children", Interop_reactApi.Children.toArray([createElement("dl", {
            className: "glossary",
            children: Interop_reactApi.Children.toArray([createElement("dt", {
                children: ["Average rating time trend"],
            }), createElement("dd", {
                children: ["The best fitting polynomial of order 4 or less is used to show the time trend of the ratings.\n                                         This is adequate to show the general shape of the time trend, but regions where the curvature is shallow\n                                         (range less than 0.2) may actually be a plateau. Note: if the density of ratings at the start or end of\n                                         a game\u0027s time range is low, these are removed from this plot as they could be too influential otherwise."],
            }), createElement("dt", {
                children: ["Linked items"],
            }), createElement("dd", {
                children: ["Games listed by BoardGameGeek as being linked."],
            }), createElement("dt", {
                children: ["Like score"],
            }), createElement("dd", {
                children: ["A \"Bayesian average\" of the User and Game scores weighted by their relative precision."],
            }), createElement("dt", {
                children: ["User score"],
            }), createElement("dd", {
                children: ["A score based on the ratings difference between those who liked the selected game and those who did not like it or have not rated it."],
            }), createElement("dt", {
                children: ["Game score"],
            }), createElement("dd", {
                children: ["A score based on the shared attributes of the recommended game and selected game, which represents the information available prior to anyone playing the game."],
            }), createElement("dt", {
                children: ["Strength"],
            }), createElement("dd", {
                children: ["The Like Score is used to create a 3-star rating system denoting the strength of the recommendations."],
            }), createElement("dt", {
                children: ["Surety"],
            }), createElement("dd", {
                children: ["The reliability of the Like Score value depends on the number of raters and the variation in their ratings. A 3-star rating system denotes how sure you can be in the Like Score value."],
            })]),
        })])]]), createElement("section", createObj(Helpers_combineClasses("modal-card-body", props_21))))]), createElement("div", {
            className: "modal-card",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]);
    }))));
    return createElement("div", createObj(Helpers_combineClasses("modal", props_24)));
}

export function rankingsGlossaryModal(isActive, close) {
    const props_36 = toList(delay(() => append(isActive ? singleton(["className", "is-active"]) : empty(), delay(() => {
        let elms_2, elms, props_33, children_16, elms_1, children_3, children_1, children_13, children_5, children_7, children_9, children_11;
        return singleton(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", close])))), (elms_2 = ofArray([(elms = ofArray([createElement("p", {
            className: "modal-card-title",
            children: "Glossary",
        }), createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", close]))))]), createElement("header", {
            className: "modal-card-head",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_33 = ofArray([["className", "more-info-body"], ["children", Interop_reactApi.Children.toArray([createElement("dl", {
            className: "glossary",
            children: Interop_reactApi.Children.toArray([createElement("dt", {
                children: ["Average rating time trend"],
            }), createElement("dd", {
                children: ["The best fitting polynomial of order 4 or less is used to show the time trend of the ratings.\n                                         This is adequate to show the general shape of the time trend, but regions where the curvature is shallow\n                                         (range less than 0.2) may actually be a plateau. Note: if the density of ratings at the start or end of\n                                         a game\u0027s time range is low, these are removed from this plot as they could be too influential otherwise.\n                                         Only visible at resolutions of 1024px and wider."],
            }), createElement("dt", {
                children: ["Time period"],
            }), createElement("dd", {
                children: ["If \"All\" is not selected then  only the most recent ratings are used to rank the games.\n                                        The purpose of this setting is to allow games that are currently popular to be identified.\n                                        If combined with a \"New game adjustment\" then it can be used to identify games that have survived\n                                        the test of time."],
            }), createElement("dt", {
                children: ["New game adjustment"],
            }), createElement("dd", {
                children: ["If \"None\" is not selected then the games with less than 10 years of ratings data use a prediction\n                                        of the mean rating for that game after it would have 10 years of ratings. A statistical analysis of the\n                                        BGG database was undertaken to derive multiple regression models that provide these predictions. As it\n                                        showed that most games become less popular over time, this will penalise games with only a few years\u0027\n                                        worth of ratings. As the number of years gets closer to 10 this adjustment will have  less impact. The\n                                        \"Base\" adjustment gives an \"unbiased\" prediction, with \"Mild\", \"Moderate\" and \"Severe\" adjustments\n                                        reducing the predictions by one, two or three standard errors respectively. The purpose of this adjustment\n                                        is to try and eliminate the \"Cult of the New\" effect."],
            }), createElement("dt", {
                children: ["Pseudo raters"],
            }), (children_16 = ofArray(["This is the same as is used by BGG, being the number of additional pseudo raters of 5.5\n                                            that are included in the calculation. Based on a statistical analysis of the BGG database\n                                            (2022 December), a value of 2100 is recommended to most closely mimic the BGG rankings. It is not\n                                            possible to replicate exactly because BGG pre-process the ratings in an unspecified manner in an\n                                            attempt to minimise the influence of shillers. We also use an algorithm to remove very early,\n                                            typically pre-release, raters which are identified by their relatively low \"temporal density\".\n                                            We also remove raters who do not discriminate between the games they rank as these appear to be\n                                            mainly users who are not using the ratings as intended. Our recommendations for all Time Period\n                                            settings are:", (elms_1 = ofArray([(children_3 = singleton_1((children_1 = ofArray([createElement("th", {
                children: ["Time Period"],
            }), createElement("th", {
                children: ["Pseudo-Raters"],
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_1)),
            }))), createElement("thead", {
                children: Interop_reactApi.Children.toArray(Array.from(children_3)),
            })), (children_13 = ofArray([(children_5 = ofArray([createElement("td", {
                children: ["All"],
            }), createElement("td", {
                children: ["2100"],
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_5)),
            })), (children_7 = ofArray([createElement("td", {
                children: ["4 years"],
            }), createElement("td", {
                children: ["1700"],
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_7)),
            })), (children_9 = ofArray([createElement("td", {
                children: ["2 years"],
            }), createElement("td", {
                children: ["1000"],
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_9)),
            })), (children_11 = ofArray([createElement("td", {
                children: ["1 year"],
            }), createElement("td", {
                children: ["500"],
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_11)),
            }))]), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(children_13)),
            }))]), createElement("table", {
                className: "table",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            })), "Note that BGG periodically update the number of pseudo-raters they use.\n                                            We estimate this has increased by about 15% over the last 2 years."]), createElement("dd", {
                children: Interop_reactApi.Children.toArray(Array.from(children_16)),
            }))]),
        })])]]), createElement("section", createObj(Helpers_combineClasses("modal-card-body", props_33))))]), createElement("div", {
            className: "modal-card",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))])]);
    }))));
    return createElement("div", createObj(Helpers_combineClasses("modal", props_36)));
}

export function moreInfoModal(isActive, closeDisplay) {
    const props_16 = toList(delay(() => append(isActive ? singleton(["className", "is-active"]) : empty(), delay(() => {
        let elms_1, elms, props_13, children_1;
        return singleton(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", closeDisplay])))), (elms_1 = ofArray([(elms = ofArray([createElement("p", {
            className: "modal-card-title",
            children: "What\u0027s it doing?",
        }), createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", closeDisplay]))))]), createElement("header", {
            className: "modal-card-head",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_13 = ofArray([["className", "more-info-body"], ["children", Interop_reactApi.Children.toArray([(children_1 = ofArray([createElement("span", {
            children: ["An article describing how this recommender system was created was published in\n                                      Significance, the magazine of the Royal Statistical Society and American Statistical Association"],
        }), createElement("a", {
            href: "https://rss.onlinelibrary.wiley.com/doi/10.1111/j.1740-9713.2019.01317.x",
            rel: "noopener",
            target: "_blank",
            children: "https://rss.onlinelibrary.wiley.com/doi/10.1111/j.1740-9713.2019.01317.x",
        })]), createElement("p", {
            children: Interop_reactApi.Children.toArray(Array.from(children_1)),
        })), createElement("p", {
            children: ["Imagine you have just finished playing a boardgame that you really enjoyed and asked those around you if they could recommend another game.\n                                 Recommendations are likely to be more reliable if they come from someone who also enjoy the game you have just played, and they have a broad\n                                 knowledge of other board games. If you could ask thousands of people who have played the game and collate their responses, taking account\n                                 of whether they did or did not like the game, it should be possible to devise a useful recommendation algorithm. This application does this\n                                 by utilising the ratings given by users of the BoardGameGeek website."],
        }), createElement("p", {
            children: ["The method recommends those games that provide the biggest ratings contrast between those users who enjoyed the original game and those who\n                                 either did not enjoy it or have not rated it. This contrast is scaled to lie approximately on the 0 to 10 range and shown as the User Score.\n                                 The User Score is also adjusted to remove an unwanted bias that is evident between games published in a similar time period. Clearly the more\n                                 users who have rated both games, the more reliable will be the User Score. A Bayesian method has been used to combine information available\n                                 prior to anyone playing the game with the information contained in the ratings data. This prior information comes from the attributes of each\n                                 game, such as the designer, mechanics and playing time. The Game Score, scaled to be comparable to the User Score, quantifies the prior\n                                 information. The Bayesian method combines these scores, weighting them according to the relative precisions of the User and Game Scores, to\n                                 give the Like Score. It is this value that is used to order the recommendations."],
        }), createElement("p", {
            children: ["The Like Scores are also used to provide a Strength rating, with 3 stars implying a strong recommendation. Clearly it is not possible for the\n                                 algorithm to know which aspects of the game particularly appealed to you, so there can be no guarantee that any of the recommendations will\n                                 be good for you. All that can be inferred is that the games recommended are liked significantly more by those who liked the original game\n                                 than those who did not like it or have not rated it. Also, games are only recommended if those who liked the original game gave it a good\n                                 rating (\u003e7) on average."],
        }), createElement("p", {
            children: ["As well as computing the Like Score the Bayesian method also computes the precision of this score. A Surety rating is provided that represents\n                                 this precision. A Surety of 3-stars implies high precision and so one can be confident in the reliability of the Like Score. Typically, low\n                                 Surety ratings will be associated with those games that have had relatively few user ratings."],
        })])]]), createElement("section", createObj(Helpers_combineClasses("modal-card-body", props_13))))]), createElement("div", {
            className: "modal-card",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]);
    }))));
    return createElement("div", createObj(Helpers_combineClasses("modal", props_16)));
}

