import { map } from "./.fable/fable-library.3.1.12/List.js";
import { Navigation_newUrl } from "./.fable/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { toPath } from "./Pages.fs.js";
import { createElement } from "react";
import { createObj } from "./.fable/fable-library.3.1.12/Util.js";
import { singleton, append, delay, toList } from "./.fable/fable-library.3.1.12/Seq.js";
import { Helpers_combineClasses } from "./.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";

export function goToUrl(href, e) {
    e.preventDefault();
    void map((f) => {
        f((value) => {
        });
    }, Navigation_newUrl(href));
}

export function viewLink(page, props) {
    const href = toPath(page);
    return createElement("a", createObj(toList(delay(() => append(singleton(["href", href]), delay(() => append(singleton(["onClick", (e) => {
        goToUrl(href, e);
    }]), delay(() => props))))))));
}

export function viewLinkButton(page, props) {
    const href = toPath(page);
    const props_1 = toList(delay(() => append(singleton(["href", href]), delay(() => append(singleton(["onClick", (e) => {
        goToUrl(href, e);
    }]), delay(() => props))))));
    return createElement("a", createObj(Helpers_combineClasses("button", props_1)));
}

